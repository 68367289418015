import React from 'react';
import { Button } from 'react-bootstrap';
import config from '../../constant/config';
import "./Elearning.css";
import { Link } from 'react-router-dom';


class Elearning extends React.Component{
    constructor(props){
        super(props);
        this.state={
          userResults:[],
        }
    }

    async componentDidMount(){
      const res = await fetch(`${config.apiURL}/user/elearning`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'content-type': 'application/json' },
          });
          const userResults = await res.json();
          this.setState({ userResults });
    }


    renderTableRows() {
        const userResults = this.state.userResults

        return userResults.map((userResult) => {
          return (
            <tr key={userResult.courseId}>
              <td style={{color:'#14B4C8'}}><Link to={`/course/${userResult.courseId}`} >{userResult.courseName}</Link></td>
              <td style={{color:'#14B4C8'}}><Link to={`/${userResult.hubPathName}`} >{userResult.hubName}</Link></td>
              <Button className='start-course-button' onClick={()=>{window.location.assign(`/course/${userResult.courseId}`)}}>Start Course</Button>
              <td>{userResult.courseStatus}</td>
              {/* <td>{userResult.courseStatus}</td> */}
              <td>{userResult.courseDeadline}</td>
              <td>{userResult.userCompletionDate}</td>
            
            </tr>
          );
        });
      }

    render(){
        return(
            <div className='main-container-elearning-tab'>
                <div className='heading'>My Courses</div>
                {this.state.userResults.length<=0 && <div style={{textAlign:'center',margin:'20px'}}>No courses assigned</div>}
                {this.state.userResults.length>0 &&
                <div className='table-container'>
                <table className="users-table">
          <thead >
            <tr>
              <th>Name</th>
              <th>Hub Name</th>
              <th></th>
              {/* <th >Progress </th> */}
              <th >Status</th>
              <th> Deadline</th>
              <th>Completion Date</th>            
            </tr>
          </thead>
          <tbody>
            { this.renderTableRows()}
          </tbody>
        </table>
                </div>
    }
            </div>
        )
    }
}

export default Elearning;