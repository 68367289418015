import React, { Component } from 'react';
import { Button, FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import DatePicker from 'react-date-picker';
import './AssignCourse.css';

class AssignCourse extends Component {

    constructor(props){
        super(props);
        this.state={
selectedCourse:null,
selectedDate: new Date(),
courses:this.props.coursesInHub,
        }
    }
  render() {
    const { saveCourse } = this.props;
    return (
      <div className="assign-course-modal-container">
<div>
          <div className="text-container">
            <p>
              Assign New Course
            </p>
          </div>
          <div className="dropdown-containers">
          <FormGroup className="form" controlId="formCategory">
              <div className='label-1'> Course:</div>
                <FormControl
                  className="course-select"
                  componentClass="select"
                  onChange={e => {
                    const selectedId = e.target.value;
                    const selectedCourse = this.state.courses.find(course => course.id === parseInt(selectedId, 10));
                    this.setState({ selectedCourse });
                  }}
                > {/*  Need to add code for removing the courses available in the create page,  get the courses from createCourse*/}
                  <option value="select">Select Course</option>
                  {this.state.courses.map(course => (
                    <option key={course.id} value={course.id}>{course.title}</option>
                  ))}
                </FormControl>
                </FormGroup>
                <FormGroup className="form form-group">
                <ControlLabel>Set Deadline:</ControlLabel>
                  <DatePicker
                    value={this.state.selectedDate}
                    onChange={(date) => { this.setState({ selectedDate: date }); }}
                    clearIcon={null}
                    calendarIcon={<i className="fa fa-calendar fa-2x"  style={{color:"#14B4C8"}}/>}
                  />
                </FormGroup>
          </div>
          <div className="button-container">
            <Button  className="save-button"onClick={() => saveCourse(this.state.selectedCourse,this.state.selectedDate)}>save</Button>
          </div>
</div>
      </div>
    );
  }
}

export default AssignCourse;