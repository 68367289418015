import React from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import config from '../../constant/config';
import auth from '../../lib/auth';
import ModuleHeader from '../common/ModuleHeader';
import CommentSection from '../common/CommentSectionContainer';
import Quiz from '../common/quiz/QuizContainer';
import checkStatus from '../common/checkStatus';
import { parseEmbedYouTubeUrl, parseEmbedVimeoUrl } from '../../lib/video';
import './Module.css';

import { Cookies } from "react-cookie-consent";
import { parseContent } from '../../lib/utilities';
import parse from 'html-react-parser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/free-solid-svg-icons'


class Module extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      module: [],
      hubName: '',
      categoryDescription: '',
      actionButton: null,
      showModal: false,
      embedLinks: [],
      loading: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!nextProps.match) {
      if (prevState.moduleId !== undefined && nextProps.moduleId !== prevState.moduleId) {
        return {
          loading: true,
        };
      }
    }
    return null;

  }

  componentDidMount() {
    this.getModule();

    if(this.props.moduleRendered){

        this.props.moduleRendered();
    }
    
  }
  componentDidUpdate(prevProps) {
    // Check if the moduleId has changed before calling getModule
    if (this.props.moduleId !== prevProps.moduleId) {
      this.getModule();
      if(this.props.moduleRendered){
        this.props.moduleRendered();
    }
  }
    
  }

  onEditModule() {
    this.props.history.push(`/edit/module/${this.state.module.id}`);
  }

  async onDeleteModule() {
    const moduleId = (this.props.match && this.props.match.params && this.props.match.params.moduleId) || this.props.moduleId;
    try {
      const res = await fetch(`${config.apiURL}/module/${moduleId}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        this.props.history.push('/' + this.state.module.hub.pathName);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async onBookmarkModule(e) {
    e.preventDefault();
    const moduleId = (this.props.match && this.props.match.params && this.props.match.params.moduleId) || this.props.moduleId;
    try {
      const res = await fetch(`${config.apiURL}/user/bookmark/${moduleId}`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        this.props.addBookmark(this.state.module);
        this.getModule();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async onRemoveBookmarkModule(e) {
    e.preventDefault();
    const moduleId = (this.props.match && this.props.match.params && this.props.match.params.moduleId) || this.props.moduleId;
    try {
      const res = await fetch(`${config.apiURL}/user/bookmark/${moduleId}`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        this.props.removeBookmark(parseInt(moduleId, 10));
        this.getModule();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getMembers(moduleId) {
    const res = await fetch(`${config.apiURL}/module/${moduleId}/members`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status !== 200) {
      throw new Error('could not fetch module members types');
    }
    else {
      const members = await res.json();
      return members;
    }
  }

  async getHub(pathname) {
    const res = await fetch(`${config.apiURL}/hub/name/${pathname}/`, {
      credentials: 'include',
      method: 'GET',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status !== 200) {
      throw new Error('could not fetch hub');
    }
    else {
      const hub = await res.json();
      return hub;
    }
  }

  async getModule() {
    const moduleId = (this.props.match && this.props.match.params && this.props.match.params.moduleId) || this.props.moduleId;
    this.setState({
      moduleId: moduleId
    })
    try {
      const res = await fetch(`${config.apiURL}/module/${moduleId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const module = await res.json();
        const isAdmin = await auth.isAuthenticatedHubAdmin(module.hub.id);
        const isSiteOwner = await auth.isAuthenticatedSiteOwner(module.hub.id);
        let bookmarked = false;
        if (this.props.user) {
          const i = this.props.user.bookmarks ?
            this.props.user.bookmarks.findIndex(bookmark => bookmark.id === parseInt(moduleId, 10))
            : false;
          bookmarked = !(i === -1);
        }
        const actionButton = this.renderActionButton(isAdmin, bookmarked);
        await this.setState({
          module,
          hubName: module.hub.name,
          categoryDescription: module.category.description,
          actionButton,
          loading: false
        });
        await this.setVideoEmbebedHtml();
        if (module.accessId === 3 && !(isAdmin ? true : isSiteOwner)) {
          try {
            const members = await this.getMembers(module.id);
            if (!members.map(x => x.userId).includes(this.props.user.id)) {
              this.props.history.push('/' + module.hub.pathName);
            }
          } catch (error) {
            this.props.history.push('/' + module.hub.pathName);
          }
        } else if (module.accessId === 2 && !(isAdmin ? true : isSiteOwner)) {
          try {
            const hub = await this.getHub(module.hub.pathName);
            const hubMembers = hub.users;
            if (!hubMembers.map(x => x.id).includes(this.props.user.id)) {
              this.props.history.push('/' + module.hub.pathName);
            }
          } catch (error) {
            this.props.history.push('/' + module.hub.pathName);
          }
        }
      }
    } catch (err) {
      console.log(err);
      this.setState({ loading: false })
    }
  }

  async setVideoEmbebedHtml() {
    const embedLinks = [];
    for (let i = 0; i < this.state.module.videos.length; i += 1) {
      try {
        if (Cookies.get('actifyCookieConsent') === 'true') {
          const videoURL = this.state.module.videos[i].embedURL;
          if (this.state.module.videos[i].sourceId === config.videoSource.YOUTUBE) {
            const youtubeSrc = parseEmbedYouTubeUrl(videoURL);
            const embedLink = (<iframe
              title={youtubeSrc}
              className="video-wrapper"
              src={youtubeSrc}
              frameBorder="0"
              allowFullScreen
            />);
            embedLinks.push(embedLink);
          } else if (this.state.module.videos[i].sourceId === config.videoSource.VIMEO) {
            const videoId = await parseEmbedVimeoUrl(videoURL); // eslint-disable-line
            const embedLink = <iframe title={videoId} className="video-wrapper" src={`https://player.vimeo.com/video/${videoId}`} frameBorder="0" allowFullScreen />;
            embedLinks.push(embedLink);
          } else {
            throw new Error('unsupported video format');
          }
        } else {
          const embedLink = <p>This video will store cookies while you watch it. <br /> You need to allow cookies for the video to play.</p>;
          embedLinks.push(embedLink);
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.setState({ embedLinks });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  openAsset(asset) {
    window.open(`${config.spacesURL}/${asset}`);
  }

  renderActionButton(isAdmin, bookmarked) {
    if (this.props.user && isAdmin) {
      return (
        <div className="action-button-container-top">
          {bookmarked ?
            <Button
              className="action-button module"
              onClick={e => this.onRemoveBookmarkModule(e)}
            >
              Bookmarked <FontAwesomeIcon icon={faBookmark} />
            </Button> :
            <Button
              className="action-button module"
              onClick={e => this.onBookmarkModule(e)}
            >
              Bookmark <FontAwesomeIcon icon={faBookmark} />
            </Button>
          }
          <Button className="action-button module" onClick={e => this.onEditModule(e)}>Edit</Button>
          <Button className="action-button warning" onClick={e => this.openModal(e)}>Delete</Button>
        </div>
      );
    }
    if (this.props.user && bookmarked) {
      return (
        <div className="action-button-container-top">
          <Button
            className="action-button module"
            onClick={e => this.onRemoveBookmarkModule(e)}
          >
            Bookmarked <FontAwesomeIcon icon={faBookmark} />
          </Button>
        </div>
      );
    }
    if (this.props.user && !bookmarked) {
      return (
        <div className="action-button-container-top">
          <Button
            className="action-button module"
            onClick={e => this.onBookmarkModule(e)}
          >
            Bookmark <FontAwesomeIcon icon={faBookmark} />
          </Button>
        </div>
      );
    }
    return null;
  }

  renderMedia(asset) {
    if (config.acceptedImageTypes.includes(asset.fileType)) {
      return (
        <Col key={asset.title} lg={3} md={4} sm={6} className="module-image-col">
          <div
            className="module-image-container"
            style={{ backgroundImage: `url(${config.spacesURL}/${asset.id})` }}
            onClick={() => this.openAsset(asset.id)}
          />
        </Col>

      );
    }
    return null;
  }

  renderPDFs(asset) {
    if (config.acceptedPdfTypes.includes(asset.fileType)) {
      return (
        <Col key={asset.title} sm={2} className="pdf-col" onClick={() => this.openAsset(asset.id)}>
          <i className="fa fa-file-pdf-o fa-5x" aria-hidden="true" />
          <p>{asset.title}</p>
        </Col>
      );
    }
    return null;
  }

  renderVideos() {
    if(this.state.categoryDescription==="Learning"){
      return null;
    }
    else{
      if(this.state.module.videos.length > 0)
    {const videoContent = this.state.embedLinks.map((embedLink, i) => (
      <div key={this.state.module.videos[i].id} sm={12} md={6} className="video-col">
        {embedLink}
      </div>
    ));
    return videoContent;
  }
  }
}

  renderTargetGroups() {

    return this.state.module.targetGroups.map(group => (
      <Button className="tag" onClick={(e) => this.onLinkClick(e, group.description)}>
        {group.description}
      </Button>));

  }

  renderActivities() {

    return this.state.module.activities.map(activity => (
      <Button className="tag" onClick={(e) => this.onLinkClick(e, activity.description)}>
        {activity.description}
      </Button>
    ));



  }



  onLinkClick(e, query) {
    e.preventDefault();
    this.props.history.push({ pathname: `/result/${query.replace('#', '%23')}`, query: query });
  }

  render() {
    return (
      <>
        {!this.state.loading &&
          <div className="module-page-container">
            <ModuleHeader
              mod={this.state.module}
              hubName={this.state.hubName}
              categoryDescription={this.state.categoryDescription}
              actionButton={this.state.actionButton}
              loggedIn={this.props.user}
              courseData={this.props.courseData}
            />

            <div className="content-container">
              <div className="module-content-container">

                <div className="text-content-row" >
                  <div
                    className="module-content"
                    key={Math.random()}
                  >
                    {parse(parseContent(this.state.module.textContent))}

                    <div className="video-content">
                      {this.state.embedLinks.length > 0 && this.renderVideos()}
                    </div>

                  </div>
                </div>

                {this.state.module.hub &&
                  <div className="module-tags-container">
                    {this.state.module.targetGroups.length > 0 && this.renderTargetGroups()}
                    {this.state.module.activities.length > 0 && this.renderActivities()}
                  </div>
                }

                {this.state.module.assets &&
                  this.state.module.assets.filter(asset =>
                    (config.acceptedImageTypes.includes(asset.fileType))).length > 0 &&
                  <Row>
                    <div>
                      <div className="padding-line" />
                      <h4 className="resource-heading">Images</h4>
                      {this.state.module.assets.map(asset => this.renderMedia(asset))}
                    </div>
                  </Row>
                }
                {this.state.module.assets &&
                  this.state.module.assets.filter(asset =>
                    (config.acceptedPdfTypes.includes(asset.fileType))).length > 0 &&
                  <Row>
                    <div>
                      <div className="padding-line" />
                      <h4 className="resource-heading">Resources</h4>
                      {this.state.module.assets.map(asset => this.renderPDFs(asset))}
                    </div>
                  </Row>
                }

                <div>
                  {this.state.module.categoryId == 3 && this.state.module.questions && this.state.module.questions.length > 0 &&
                    <Quiz
                      questions={this.state.module.questions.sort((a, b) => {
                        return a.questionOrder - b.questionOrder;
                      })}
                      moduleId={this.state.module.id}
                      quizCompleted = {this.props.quizCompleted}
                      // reviewResult={this.props.location.state ?
                      //   this.props.location.state.userResult : null}
                      // userId={this.props.location.state ? this.props.location.state.userId : null}
                    />}
                </div>
              </div>

              <div>
                {this.state.module.commentsEnabled === 1 &&
                  <div className="comment-content-container">
                    <CommentSection
                      moduleId={(this.props.match && this.props.match.params && this.props.match.params.moduleId) || this.props.moduleId}
                      hubId={this.state.module.hub.id}
                    />
                  </div>
                }
              </div>
            </div>

            <Modal show={this.state.showModal} onHide={this.closeModal}>
              <Modal.Header closeButton />
              <div className="module-modal-container">
                <div>
                  <h3>Delete Module</h3>
                </div>
                <div>
                  <p>Are you sure you wish to continue?</p>
                </div>
                <div className="button-container">
                  <Button onClick={() => this.onDeleteModule()}>Yes</Button>
                  <Button onClick={() => this.closeModal()}>No</Button>
                </div>
              </div>
            </Modal>

          </div>
        }
      </>
    );
  }
}

export default checkStatus(Module);
