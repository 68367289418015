import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import config from '../../constant/config';
import "./ManageCourses.css";

import { Link } from 'react-router-dom';


class ManageCourses extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      error: null,
      modalContent: null,
      courses: [],
    }
    this.closeModal = this.closeModal.bind(this);
  }

  async componentDidMount(){
    const res = await fetch(`${config.apiURL}/course/getAll`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    const courses = await res.json();
    this.setState({courses})
  }

  async onRemoveCourse(index, course) {
    //need to create a redux, so that reemoved course is reflected everywhere 
    //and change it to backend removal

    const hubId = course.hubId;
              const courseId = course.Id;
              console.log(course)
              try {
                const res = await fetch(`${config.apiURL}/hub/${hubId}/course/${courseId}`, {
                  credentials: 'include',
                  method: 'DELETE',
                  headers: { 'content-type': 'application/json' },
                });
                if (res.status === 200) {
                  this.setState((prevState) => ({
                    
                    courses: prevState.courses.filter((_, i) => i !== index),
                    showModal: false,
                    error:null
                  }));
                }
              } catch (err) {
                console.error(err);
              }
    

  }

  closeModal() {
    this.setState({
      showModal: false,
      error: null,
    });
  }

  async onRemoveClick(e, index, course) {
    e.preventDefault();
    const modalContent = (
      <div className="remove-hub-modal-container">
        <div>
          <div className="text-container">
            <p> Are you sure you want to remove <b>{this.state.courses[index].courseName}</b>?</p>
          </div>
          <div className="button-container">
            <Button onClick={() => this.onRemoveCourse(index, course)}>Yes</Button>
            <Button onClick={() => this.closeModal()}>No</Button>
          </div>
        </div>
      </div>
    );
    this.setState({
      showModal: true,
      error: null,
      modalContent,
    });
  }


  renderTableRows() {

    return this.state.courses.map((course,index) => {
      return (
        <tr key={course.Id}>
          <td style={{ color: '#14B4C8' }}><Link to={`/course/${course.Id}`} >{course.courseName}</Link></td>
          <td style={{color:'#14B4C8'}}><Link to={`/${course.hubPathName}`} >{course.hubName}</Link></td>
          <td>      <Button className='remove-button' onClick={e => this.onRemoveClick(e,index,course)}>Remove</Button></td>

        </tr>
      );
    });
  }

  render() {
    return (
      <div className='main-container-elearning-tab'>
        <div className='manage-courses-table-container'>
          <table className="users-table">
            <thead >
              <tr>
                <th>Name</th>
                <th>Hub Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {this.renderTableRows()}
            </tbody>
          </table>
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {!this.state.error && this.state.modalContent}
          {this.state.error &&
          <div className="error-feedback">
            <i className="fa fa-exclamation-circle fa-3x" aria-hidden="true" />
            <p>{this.state.error}</p>
          </div>
          }
        </Modal>
      </div>
    )
  }
}

export default ManageCourses;