import React, { Component, useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Row, Col, Button, FormGroup, ControlLabel, FormControl,
  HelpBlock, Checkbox
} from 'react-bootstrap';
import './CreateModule.css';
import './CreateCourse.css';
import config from '../../constant/config';
import DatePicker from 'react-date-picker';
import "react-datepicker/dist/react-datepicker.css";
import loadingAnimation from "../../resources/img/loading_animation.gif";

export class CreateCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outcomes: [{ id: 1, value: "" }], // Initial state with one outcome
      selectedModules: [], // Array to store selected modules\
      modules:this.props.modules,
      sections: [
        {
          id: 1,
          name: '1',
          selectedModules: [],
        }
      ],
      expandedSections: [1],
      selectedActivity: '',
      activities: [],
      activitiesSelected: [],
      activityOptions: [{
        id: 1,
        description: 'A'
      },
      {
        id: 2,
        description: 'B'
      }],
      selectedTarget: '',
      targetGroups: [],
      targetGroupsSelected: [],
      targetGroupOptions: [{
        id: 1,
        description: 'TA'
      },
      {
        id: 2,
        description: 'TB'
      }],
      groups: [],
      selectedDate: new Date(),
      selectedGroupId: null,
      savedGroups: [],
      validTitle:true,
      validDescription:true, 
      validImage:true,
      title:'',
      description:'',
      validLearningOutcome:true,
      validSection:true,



    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

   async componentDidMount(){
    console.log(this.state.modules[0])
    let groups=[];
      const resGroups = await fetch(`${config.apiURL}/hub/${this.props.hubId}/getAllGroups`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (resGroups.status == 200) {
         groups = await resGroups.json();  
      }

      this.setState({
        groups:groups
      });

  }
  openModal() {
    this.setState({ showModal: true });
  }
  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  async onPublishClick() {
    this.setState({
      published: true,
      errorMessage: "",
    });
    const validTitle = this.state.title.length > 0 && this.state.title.length < 255;
    const validDescription = this.state.description.length > 0 && this.state.description.length < 255;
    const validImage = !!this.state.featuredImage;
    const validLearningOutcome = this.state.outcomes.map(outcome => outcome.value).join('*#*#*').length > 0;
    const validSection = this.state.sections[0].selectedModules.length > 0;
    this.setState({
      validTitle, validDescription, validImage,validLearningOutcome,validSection
    });
    if (!(validTitle && validDescription  && validImage && validLearningOutcome && validSection)) {
      this.setState({ errorMessage: 'One or more fields are invalid. The invalid fields are highlighted in red.' })
      this.setState({ published: false });
      return;
    }
   this.openModal();

    let logoId = this.state.featuredImage;
    if (typeof (this.state.featuredImage) !== 'string') {
      const payload = new Buffer(this.state.featuredImage, 'binary').toString('binary');
      const featuredImageResponse = await fetch(`${config.apiURL}/asset/`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          title: this.state.featuredImageTitle,
          data: payload,
          fileType: this.state.featuredImageFileType,
          isCourse:true,
        }),
      });
      logoId = await featuredImageResponse.text();
    }
    const hubId = this.props.hubId
    const res = await fetch(`${config.apiURL}/hub/${hubId}/course/`, {
      credentials: 'include',
      method: this.props.edit ? 'PUT' : 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: this.props.course ? this.props.course.id : null,
        title: this.state.title,
        description: this.state.description,
        learningOutcomes:this.state.outcomes.map(outcome => outcome.value).join('*#*#*'),
        logoId,
        user:this.props.user,
        sections:this.state.sections,
        groups:this.state.savedGroups,
      }),
    });
    console.log(res.status)
    if (res.status === 200) {
      const body = await res.json();
      console.log("successfully inserted the course")
      this.setState({ published: false, showModal: false });
      this.props.history.push(`/course/${body.id}`);
      window.location.assign(`/course/${body.id}`);
    } else {
      // TODO: handle error on publishing module
    }
    this.setState({ published: false });
  }

  // Add new learning outcome
  addOutcome = () => {
    this.setState((prevState) => ({
      outcomes: [
        ...prevState.outcomes,
        { id: prevState.outcomes.length + 1, value: "" } // Add new outcome with incremented id
      ]
    }));
  };

  // Handle learning outcome input change
  handleOutcomeChange = (index, event) => {
    const newOutcomes = [...this.state.outcomes];
    newOutcomes[index].value = event.target.value;
    this.setState({ outcomes: newOutcomes });
  };



  // Add new section
  addSection = () => {
    this.setState((prevState) => {
      // Get the id of the last section, if sections array is not empty
      const lastSectionId = prevState.sections.length > 0
        ? prevState.sections[prevState.sections.length - 1].id
        : 0; // If no sections exist, start with id 1

      return {
        sections: [
          ...prevState.sections,
          { id: lastSectionId + 1, selectedModules: [], name: lastSectionId + 1 } // Add new section with incremented id
        ],
        expandedSections: [
          ...prevState.expandedSections,
          lastSectionId + 1 // Sync expandedSections with the new section id
        ]
      };
    });
  };

  handleSelect = (sectionId, e) => {
    const moduleId = parseInt(e.target.value);
    const selectedModule = this.state.modules.find((mod) => mod.id === moduleId);

    if (selectedModule) {
      this.setState((prevState) => ({
        sections: prevState.sections.map((section) => {
          if (section.id === sectionId) {
            // Check if the module already exists in the section
            const alreadySelected = section.selectedModules.some((mod) => mod.id === moduleId);

            // If it doesn't exist, add it, otherwise return the section as is
            if (!alreadySelected) {
              return {
                ...section,
                selectedModules: [...section.selectedModules, selectedModule]
              };
            }
          }
          return section;
        })
      }));
    }
  };


  // Handle delete of a selected module from a specific section
  handleDeleteModule = (sectionId, moduleId) => {
    this.setState((prevState) => ({
      sections: prevState.sections.map((section) =>
        section.id === sectionId
          ? { ...section, selectedModules: section.selectedModules.filter(mod => mod.id !== moduleId) }
          : section
      )
    }));
  };

  handleToggleCollapse = (sectionId) => {
    this.setState((prevState) => {
      const expandedSections = [...prevState.expandedSections];
      const sectionIndex = expandedSections.indexOf(sectionId);

      if (sectionIndex > -1) {
        // If section is already expanded, remove it
        expandedSections.splice(sectionIndex, 1);
      } else {
        // Otherwise, add the sectionId to the array
        expandedSections.push(sectionId);
      }
      console.log(expandedSections)
      return { expandedSections };
    });
  };;

  handleDeleteSection = (sectionId) => {
    this.setState((prevState) => ({
      sections: prevState.sections.filter(section => section.id !== sectionId),  // Remove the section
      expandedSections: prevState.expandedSections.filter(id => id !== sectionId) // Remove from expanded sections
    }));
  };

  handleSectionNameChange = (sectionId, event) => {
    const newName = event.target.value; // Get the new name from the input field
    this.setState((prevState) => ({
      sections: prevState.sections.map(section =>
        section.id === sectionId
          ? { ...section, name: newName } // Update the name of the section with the given id
          : section
      )
    }));
  };

  handleDeleteOutcome = (index) => {
    this.setState((prevState) => ({
      outcomes: prevState.outcomes.filter((_, i) => i !== index)
    }));
  };

  onChangeFile(e) {
    e.preventDefault();
    const selectedFiles = this.profileAvatarFileInput.files;
    this.setState({
      featuredImage1: URL.createObjectURL(e.target.files[0]),
    });
    this.readFile(selectedFiles[0]);
  }
  readFile(file) {
    const reader = new FileReader();
    const acceptedList = ['image/PNG', 'image/png', 'image/jpeg', 'image/jpg', 'image/JPEG'];
    reader.onloadend = () => {
      if (file.size > 5000000) {
        this.setState({
          featuredImage: null,
          featuredImageHelpText: 'Files must be less than 5mb',
          featuredImageFileType: '',
          featuredImageTitle: '',
          validImage: false,
        });
        return;
      }
      if (!acceptedList.includes(file.type)) {
        this.setState({
          featuredImage: null,
          featuredImageHelpText: 'Images must be jpeg or png',
          featuredImageFileType: '',
          featuredImageTitle: '',
          validImage: false,
        });
        return;
      }
      this.setState({
        featuredImage: reader.result,
        featuredImageFileType: file.type,
        featuredImageHelpText: '',
        featuredImageTitle: file.name,
        validImage: true,
      });
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  }

  saveGroup() {
    const chosenGroup = this.state.groups.find(group => group.id === Number(this.state.selectedGroupId));

    this.setState(prevState => ({
      savedGroups: [
        ...prevState.savedGroups, // Spread the existing savedGroups array
        {
          
          id: prevState.selectedGroupId, // Add new group details
          name:chosenGroup.name,
          deadline: prevState.selectedDate.toLocaleDateString()
        }
      ]
    }));
  }

  handleDeleteGroup(groupId){
    this.setState((prevState) => ({
      savedGroups: prevState.savedGroups.filter((group) => Number(group.id) !== Number(groupId))
    }));

  }


  render() {
    return (
      <div className="create-module-tab-container" >
        <div className="padding">
          <div className="form-heading-container">
          <div className='back-button-container'>
              <Button className='back-button' onClick = { () => {this.props.onClickBack()}}>Back</Button>
            </div >
            <span>
              <h1 className="form-heading">
                {this.props.edit ? 'Edit Course' : 'Create a New Course'}
              </h1>
            </span>
          </div>
          <div className="form-container">
            <FormGroup className="form" controlId="title" >
              <ControlLabel>Course Title:</ControlLabel>
              <div style={{ flex: '1 1' }}>
                <FormControl
                  autoFocus
                  className="form-input"
                  type="text"
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
                {!this.state.validTitle && <HelpBlock>Must Enter Course Title</HelpBlock>}
              </div>
            </FormGroup>
            <FormGroup className="form" controlId="description" >
              <ControlLabel>Course Description:</ControlLabel>
              <div style={{ flex: '1 1' }}>
                <FormControl
                  className="form-input"
                  type="text"
                  value={this.state.description}
                  onChange={e => this.setState({ description: e.target.value })}
                />
                {!this.state.validDescription && <HelpBlock>Must Enter Course Purpose</HelpBlock>}
              </div>
            </FormGroup>

            <div>
              {this.state.outcomes.map((outcome, index) => (
                <FormGroup className="form" controlId={`learningOutcome-${outcome.id}`} key={outcome.id}>
                  <ControlLabel>Learning Outcome {outcome.id}:</ControlLabel>
                  <div style={{ flex: '1 1', paddingLeft: index==0?'0':'2.4%' }}>
                    <FormControl
                      className="form-input"
                      type="text"
                      value={outcome.value}
                      onChange={(e) => this.handleOutcomeChange(index, e)}
                    />
              {!this.state.validLearningOutcome && <HelpBlock>{'Aleast one learning outcome is required'}</HelpBlock>}
                  </div>
                  {index!==0 && <button
                    type="button"
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      color: 'red',
                      fontSize: '20px',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.handleDeleteOutcome(index)}
                  >
                    &#x2716; {/* Unicode character for the cross (X) symbol */}
                  </button>
  }
                </FormGroup>
              ))}
              <Button className='blue-button' onClick={this.addOutcome}>Add Outcome</Button>
            </div>
          

            <FormGroup className="form" controlId="form" >
              <div style={{ display: "block", flex: "1" }}>
                <ControlLabel className="featured-title">Thumbnail Image:</ControlLabel>
                <HelpBlock>Recommended Size 180 x 100</HelpBlock>
              </div>
              <div className="form-button-container-featureImage">
                <img className='img1' alt="featured" src={this.state.featuredImage1} />
                <Button className="attach-button-featureImage button" onClick={() => this.profileAvatarFileInput.click()}>
                  Upload
                </Button>
                {!this.state.validImage && <HelpBlock>{this.state.featuredImageHelpText || 'Course Thumbnail is required'}</HelpBlock>}
              </div>
            </FormGroup>
            {/* Module or Quiz selection */}
            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ControlLabel>Sections:</ControlLabel>
              </div>
              {!this.state.validSection && <HelpBlock>{ 'Aleast one Section is required'}</HelpBlock>}

            </div>
            {/* Sections for Module/Quiz */}
            <div>
              {this.state.sections.map((section,index) => (
                <div key={section.id} style={{ marginBottom: '20px' }}>
                  <div >
                    <input
                      type="text"
                      className="section-label"
                      defaultValue={`Section ${section.name}`}
                      onChange={(e) => this.handleSectionNameChange(section.id, e)} // Editable name input
                    />
                    <button className='arrow-button' onClick={() => this.handleToggleCollapse(section.id)}>
                      {this.state.expandedSections.includes(section.id) ? '\u25BC' : '\u25B6'}
                    </button>

                    {index!==0 && <button className='delete-button' onClick={() => this.handleDeleteSection(section.id)}>Delete Section</button>}

                  </div>
                  {/* Conditionally render the collapsible content */}
                  {this.state.expandedSections.includes(section.id) && (
                    <div className='module-selection'>
                      <hr></hr>
                      <ControlLabel>Select Module or Quiz</ControlLabel>
                      {section.selectedModules.map((selectedModule) => (
                        <div key={selectedModule.id} style={{ padding: '10px', marginTop: '10px' }}>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='module-creator-display'>
                              <h4 style={{ fontWeight: 'bold' }}>{selectedModule.title}</h4>
                              </div>
                              <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}>
                              <p>Created by {selectedModule.createdBy.firstName} {selectedModule.createdBy.surname}</p>
                            </div>
                            <button
                              className="delete-button"
                              onClick={() => this.handleDeleteModule(section.id, selectedModule.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                      <div className="dropdown">
                        <select onChange={(e) => this.handleSelect(section.id, e)}>
                          <option value="">Select Module or Quiz </option>
                          {this.state.modules.map((module) => (
                            <option key={module.id} value={module.id} disabled={module.accessId == 3}>
                              {module.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      <hr></hr>
                    </div>
                  )}
                </div>
              ))}

              <Button className='blue-button-center' onClick={this.addSection}>Add New Section</Button>
            </div>
            <hr style={{height:'10px'}}></hr>
            <div className='group-selection'>
                <div>
                {this.state.savedGroups.map((group) => (
                  <div key={group.id} style={{ padding: '10px', marginTop: '10px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div className='module-creator-display'>
                        <h4 style={{ fontWeight: 'bold' }}>{group.name}</h4>
                        </div>
                        <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold' }}> <p>Deadline: {group.deadline}</p></div>

                      <button
                        className="delete-button"
                        onClick={() => this.handleDeleteGroup(group.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                ))}
                </div>
              <div className='group-div'>
                <ControlLabel>Assign to:</ControlLabel>
                <div className="dropdown-group">
                  <select
                    onChange={e => {
                      this.setState({ selectedGroupId: e.target.value });
                    }}>
                    <option value={this.state.selectedGroupId}>Select Group </option>
                    {this.state.groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='group-div'>
              <ControlLabel>Set Deadline:</ControlLabel>
                <FormGroup className="form form-group">
                
                  <DatePicker
                    value={this.state.selectedDate}
                    onChange={(date) => { this.setState({ selectedDate: date }); }}
                    clearIcon={null}
                    calendarIcon={<i className="fa fa-calendar fa-2x" />}
                  />
                </FormGroup>
              </div>
              <div className="publish-button-container">
                <Button className="publish-button button" onClick={() => this.saveGroup()}>
                  Save Group
                </Button>
              </div>
            </div>
            <hr></hr>
            {/* <FormGroup className="form" controlId="description" >
              <ControlLabel>Estimated Time:</ControlLabel>
              <div style={{ flex: '1 1' }}>
                <FormControl
                  className="form-input-small"
                  type="text"
                  defaultValue="00:00:00"
                />
                {!this.state.validDescription && <HelpBlock>Must Enter Module Purpose</HelpBlock>}
              </div>
            </FormGroup> */}

            {/* <FormGroup className="form" controlId="description" >
              <ControlLabel>Set Time Limit:</ControlLabel>
              <div style={{ flex: '1 1' }}>
                <FormControl
                  className="form-input-small"
                  type="text"
                  defaultValue="00:00:00"
                />
                {!this.state.validDescription && <HelpBlock>Must Enter Module Purpose</HelpBlock>}
              </div>
            </FormGroup> */}

            

           </div>


        <div className="publish-feedback">
          {/* {this.state.published && (this.props.edit ? 'Updating Course...' : 'Creating Course...')} */}
        </div>
        {this.state.errorMessage &&
          <span style={{ marginRight: '1em', color: '#a94442' }}>
            {this.state.errorMessage}
          </span>
        }
        <div className="publish-button-container">
          <Button className="publish-button button" onClick={() => this.onPublishClick()}>
            {this.props.edit ? 'Update' : 'Publish'} 
          </Button>
        </div>
        {this.state.showModal && <div className='blur' />}
          {this.state.showModal && (
            <div className="gif-container" backdropOpacity={0.3}>
              <img className='gif' src={loadingAnimation} alt="loading" />
            </div>)}

          <div className="publish-feedback">
            {this.state.published && (this.props.edit ? 'Updating course...' : 'Creating course...')}
          </div>
        <div style={{ display: 'none' }}>
          <input
            ref={(f) => { this.profileAvatarFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeFile(e)}
            accept="image/*"
          />

          <input
            ref={(f) => { this.moduleImageFileInput = f; }}
            className="form-input logo"
            type="file"
            onChange={e => this.onChangeImageFiles(e)}
            accept="image/jpeg,image/png,application/pdf,.zip"
          />
        </div>

      </div >
      </div >
    );

  }
}

export default withRouter(CreateCourse);