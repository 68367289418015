import React, { Component } from 'react';
import { Button, FormGroup, FormControl, Modal, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { removeHub, unfollowHub } from '../../store/actions';
import config from '../../constant/config';
import './ManageHubs.css';
import ChangeElearningStatus from './ChangeElearningStatus';

class ManageHubs extends Component {
  //Need to handle the checking and unchecking of eleraning, use the modal pop for this.
  constructor(props) {
    super(props);
    this.state = {
      pendingHubs: [],
      approvedHubs: [],
      showModal: false,
      error: null,
      modalContent: null,
    };
    this.closeModal = this.closeModal.bind(this);
    this.onChangingElearningStatus= this.onChangingElearningStatus.bind(this);
  }

  componentWillMount() {
    this.getHubList();
  }

  async onApproveHubClick(e, index) {
    e.preventDefault();
    const { pendingHubs } = this.state;
    const hub = pendingHubs[index];
    const { newPathName } = hub;
    if (!newPathName || !(newPathName.length > 0)) {
      this.setState({
        showModal: true,
        error: 'hub address can not be empty',
      });
      return;
    }
    try {
      const res = await fetch(`${config.apiURL}/hub/${hub.id}/approve`, {
        method: 'PUT',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          pathName: newPathName,
        }),
      });
      if (res.status === 200) {
        const approvedHub = Object.assign({}, pendingHubs[index], { pathName: newPathName });
        pendingHubs.splice(index, 1);
        this.setState({
          approvedHubs: [...this.state.approvedHubs, approvedHub],
          pendingHubs,
        });
      } else {
        if (res.status === 400) {
          this.setState({
            showModal: true,
            error: 'Unable to approve hub. Please try again.',
          });
        }
        throw new Error('unable to approve hub');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async onRejectHubClick(e, index) {
    e.preventDefault();
    const { pendingHubs } = this.state;
    const hub = pendingHubs[index];

    try {
      const res = await fetch(`${config.apiURL}/hub/${hub.id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        pendingHubs.splice(index, 1);
        this.setState({
          pendingHubs,
        });
      } else {
        if (res.status === 400) {
          this.setState({
            showModal: true,
            error: 'Unable to reject hub. Please try again.',
          });
        }
        throw new Error('unable to reject hub');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async onRemoveHubClick(e, index) {
    e.preventDefault();
    const modalContent = (
      <div className="remove-hub-modal-container">
        <div>
          <div className="text-container">
            <p> Removing a hub will result also remove all associated content.
              <br />Such as Modules, Plans and Sessions.<br />
              <br />Are you sure you with to remove <b>{this.state.approvedHubs[index].name}</b>?
            </p>
          </div>
          <div className="button-container">
            <Button onClick={() => this.onRemoveHub(index, this.state.approvedHubs[index].id)}>Yes</Button>
            <Button onClick={() => this.closeModal()}>No</Button>
          </div>
        </div>
      </div>
    );
    this.setState({
      showModal: true,
      error: null,
      modalContent,
    });
  }

  async onRemoveHub(index, hubId) {
    const { approvedHubs } = this.state;
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}/`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        this.props.onRemoveHub(hubId);
        approvedHubs.splice(index, 1);
        this.setState({
          approvedHubs,
          showModal: false,
          error: null,
        });

      } else {
        this.setState({
          showModal: true,
          error: 'Unable to remove hub. Please try again.',
        });
        throw new Error('unable to remove hub');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getHubList() {
    try {
      const pendingHubsRes = await fetch(`${config.apiURL}/hub/pending`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (pendingHubsRes.status !== 200) {
        throw new Error('unable to fetch pending hubs');
      }
      const pendingHubs = await pendingHubsRes.json();
      const approvedHubsRes = await fetch(`${config.apiURL}/hub`, {
        method: 'GET',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      const approvedHubs = await approvedHubsRes.json();
      this.setState({
        pendingHubs,
        approvedHubs,
      });
    } catch (err) {
      console.error(err.message);
    }
  }

  closeModal() {
    this.setState({
      showModal: false,
      error: null,
    });
  }
  onChangingElearningStatus(e,hub){
    this.setState({
      showModal: true,
      modalContent: <ChangeElearningStatus
      closeModal={() => this.closeModal()}
      changeStatus={() => this.changeStatus(hub)}
      hubName={hub.name}
      status={hub.has_elearning}
    />,
    });
  }
  async changeStatus(hub){

    try{
const hubId = hub.id
      const res=await fetch(`${config.apiURL}/hub/elearningStatus/${hubId}`,{
        credentials:'include',
        method:'PUT',
        headers: { 'content-type': 'application/json' },
        body:JSON.stringify({
          elearningStatus: !hub.has_elearning
        })
      })

      if(res.status===200){
    this.setState((prevStat) => ({
      approvedHubs: prevStat.approvedHubs.map((h) =>
        h.id === hub.id ? { ...h, has_elearning: !hub.has_elearning } : h
      ),
      showModal:false,
    }),
    )}
    else{
      this.setState({
        showModal: true,
       error:'An error occured during the update.'
      });
    }

  }
  catch(error){

  }
}


  renderPendingTable() {
    return this.state.pendingHubs.map((hub, index) => (
      <tr key={hub.id}>
        <td><Link to={`/${hub.pathName}`} >{hub.name}</Link></td>
        <td>{hub.organisation}</td>
        <td className="path-input">
          <span className="hub-path-prefix">actify.org.uk/</span>
          <FormGroup className="hub-path-group" controlId={`huburl${hub.id}`}>
            <FormControl
              className="hub-path-input"
              value={this.state.pendingHubs[index].newPathName || ''}
              onChange={(e) => {
                const { pendingHubs } = this.state;
                pendingHubs.splice(index, 1, Object.assign({}, hub, { newPathName: e.target.value.trim() }));
                this.setState({
                  pendingHubs,
                });
              }}
            />
          </FormGroup>
        </td>
        <td>
          <div className="manage-hub-button-container">
            <Button className="pending-button" onClick={e => this.onApproveHubClick(e, index)}>Approve</Button>
          </div>
        </td>
        <td>
          <div className="manage-hub-button-container">
            <Button className="remove-button" onClick={e => this.onRejectHubClick(e, index)}>Reject</Button>
          </div>
        </td>
      </tr>
    ));
  }


  renderApprovedTable() {
    return this.state.approvedHubs.map((hub, index) => (
      <tr key={hub.id}>
        <td><Link to={`/${hub.pathName}`} >{hub.name}</Link></td>
        <td>{hub.organisation}</td>
        <td className="path-input">
          <span className="hub-path-prefix">actify.org.uk/</span>
          <span className="hub-path">{hub.pathName}</span>
        </td>
        <td>
          <Checkbox 
          checked={hub.has_elearning}
          onChange={(e) => {this.onChangingElearningStatus(e,hub)}}></Checkbox>
        </td>
        <td>
          <div className="manage-hub-button-container">
            <Button className="remove-button" onClick={e => this.onRemoveHubClick(e, index)}>Remove</Button>
          </div>
        </td>
      </tr>
    ));
  }

  render() {
    return (
      <div className="manage-hubs">
        { this.state.pendingHubs.length > 0 &&
          <div className="pending-table-container">
            <h4>Pending approval</h4>
            <table className="pending-table">
              <thead>
                <tr>
                  <th>Hub Name</th>
                  <th>Organisation</th>
                  <th>Create Site Address</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.renderPendingTable()}
              </tbody>
            </table>
          </div>
        }
        {this.state.approvedHubs.length > 0 &&
          <div className="approved-table-container">
            <h4>Approved</h4>
            <table className="approved-table">
              <thead>
                <tr>
                  <th>Hub Name</th>
                  <th>Organisation</th>
                  <th>Site Address</th>
                  <th>elearning</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {this.renderApprovedTable()}
              </tbody>
            </table>
          </div>
        }
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {!this.state.error && this.state.modalContent}
          {this.state.error &&
          <div className="error-feedback">
            <i className="fa fa-exclamation-circle fa-3x" aria-hidden="true" />
            <p>{this.state.error}</p>
          </div>
          }
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  onRemoveHub: (hubId) => {
    dispatch(removeHub(hubId));
    dispatch(unfollowHub(hubId));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ManageHubs);
