import React from 'react';
import { Button, Checkbox, ProgressBar, Modal } from 'react-bootstrap';
import checkStatus from './checkStatus';
import './Course.css';
import viewModuleImg from '../../resources/img/ViewModule.png';
import { hubAccessLevels } from '../../constant/config';
import Module from '../views/Module';
import config from '../../constant/config';

import { withRouter, Link } from 'react-router-dom';

export class Course extends React.Component {
    // while integration remember to call the backend only after section completion.
    // need to work on the complete section button, based on the quiz completion
    constructor(props) {
        super(props);
        this.state = {

            showMainPage: true,
            showModulePage: false,
            progressPercentage: 60,
            currentSectionIndex: 0,
            currentModuleIndex: 0,
            currentQuizPassed: false,
            showModal: false,
            quizCompletion: false,
            moduleLoaded: true,
            progress: 0,
            totalModules: 0,

            course: {
                learningOutcomes: [],
                "sections": [
                    {
                        "name": "Section ",
                        "modules": [{ id: 574, name: "Meet Vivienne MacLaren", completionStatus: false }, { id: 408, name: "Introduction from Chair, Amanda Jones", completionStatus: false }]
                    },
                    {
                        "name": "Section Two",
                        "modules": [{ id: 425, name: "Meet Maureen McGonigle", completionStatus: false }, { id: 423, name: "Meet Margot McCuaig", completionStatus: false }, { id: 1963, name: "Quiz", completionStatus: false }]
                    }
                ]
            }


        }
        this.openDeleteModal = this.openDeleteModal.bind(this);
        this.openCongratulationsModal = this.openCongratulationsModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    async componentDidMount() {

        const courseId = (this.props.match && this.props.match.params && this.props.match.params.courseId);

        const { currentSectionIndex, currentModuleIndex, course } = this.state;

        const selectedModuleId = course.sections[currentSectionIndex].modules[currentModuleIndex].id || null;

        this.setState({ selectedModuleId });

        try {
            const res = await fetch(`${config.apiURL}/course/${courseId}`, {
                credentials: 'include',
                method: 'GET',
                headers: { 'content-type': 'application/json' },
            });
            if (res.status === 200) {
                const course = await res.json();

                console.log(course)

                const { currentSectionIndex, currentModuleIndex } = this.state;

                const selectedModuleId = course.sections[currentSectionIndex].modules[currentModuleIndex].id || null;

                this.setState({ selectedModuleId, course });


                const totalModules = this.state.course.sections.reduce((acc, section) => acc + section.moduleCount, 0);

                this.setState({ totalModules })
            }
            else if (res.status === 401) {
                this.setState({ noAccess: true })
            }
        }
        catch (error) {
            console.log(error)
            //this.props.history.push('/' + module.hub.pathName);
        }

    }

    openDeleteModal() {
        this.setState({ showModal: true, showDeleteModal: true });
    }

    openCongratulationsModal() {
        this.setState({ showModal: true, showCongratulationsModal: true });
    }

    closeModal() {
        if (this.state.showCongratulationsModal) {
            this.setState({
                showModal: false,
                showMainPage: false,
                showModulePage: true,
                showCongratulationsModal: false,
            })
            window.location.reload();
        }
        else {
            this.setState({ showModal: false, showDeleteModal: false });
        }
    }

    async onDeleteCourse() {
        const hubId = this.state.course.hubId;
        const courseId = this.state.course.id;
        try {
            const res = await fetch(`${config.apiURL}/hub/${hubId}/course/${courseId}`, {
                credentials: 'include',
                method: 'DELETE',
                headers: { 'content-type': 'application/json' },
            });
            if (res.status === 200) {
                this.props.history.push('/' + this.state.course.pathName);
            }
        } catch (err) {
            console.error(err);
        }
    }



    async handleNext() {
        const { currentSectionIndex, currentModuleIndex, course } = this.state;
        const updatedCourse = { ...course }
        updatedCourse.sections[currentSectionIndex].modules[currentModuleIndex].completionStatus = true

        let completedModules = 0

        this.state.course.sections.forEach(section => {
            section.modules.forEach(module => {
                completedModules = module.completionStatus === true ? completedModules += 1 : completedModules

            });



        });
        const progress = Math.floor((completedModules / this.state.totalModules) * 100)


        const currentSection = course.sections[currentSectionIndex];

        if (currentModuleIndex < currentSection.modules.length - 1) {
            this.setState({ currentModuleIndex: currentModuleIndex + 1, course: updatedCourse, quizCompletion: false, progress });
        } else if (currentSectionIndex < course.sections.length - 1) {
            this.setState({
                currentSectionIndex: currentSectionIndex + 1,
                quizCompletion: false,
                // moduleLoaded: false,
                currentModuleIndex: 0,
                course: updatedCourse,
                progress

            });
        }
        else if (currentSectionIndex === course.sections.length - 1 && currentModuleIndex === currentSection.modules.length - 1) {

            try {

                const res = await fetch(`${config.apiURL}/course/${this.state.course.id}/userStatus/${2}`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: { 'content-type': 'application/json' },
                });
                if (res.status === 200) {
                    this.openCongratulationsModal();
                }
            }
            catch (err) {
                console.log('Unable to save the completion')
            }
        }
    };

    handlePrevious() {
        const { currentSectionIndex, currentModuleIndex, course } = this.state;

        if (currentModuleIndex === 0 && currentSectionIndex === 0) {
            this.setState({
                showMainPage: true,
                showModulePage: false,
                quizCompletion: false,
                // moduleLoaded: false,
            })
        }
        else {
            if (currentModuleIndex > 0) {
                // Move to the previous module in the current section
                this.setState({ currentModuleIndex: currentModuleIndex - 1, quizCompletion: false,  });
            } else if (currentSectionIndex > 0) {
                // Move to the last module of the previous section
                const prevSection = course.sections[currentSectionIndex - 1];
                this.setState({
                    currentSectionIndex: currentSectionIndex - 1,
                    currentModuleIndex: prevSection.modules.length - 1,
                    quizCompletion: false,
                    // moduleLoaded: false,
                });
            }
        }
    };


    async onClickStartCourse() {
        this.setState({
            showMainPage: false,
            showModulePage: true,
        })
        try {
            if (this.state.course.userStatus == 0) {
                const res = await fetch(`${config.apiURL}/course/${this.state.course.id}/userStatus/${1}`, {
                    method: 'PUT',
                    credentials: 'include',
                    headers: { 'content-type': 'application/json' },
                });
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    onClickHome() {
        this.setState({
            showMainPage: true,
            showModulePage: false,
            quizCompletion: false,
            // moduleLoaded: false,
        })
    }

    quizCompleted = () => {
        this.setState({
            quizCompletion: true
        })
    }

    moduleRendered = () => {
        this.setState({
            // moduleLoaded: true
        })
        window.scrollTo({
            top: 0, // Half of the total height
            behavior: "smooth", // Smooth scrolling effect
          });
    }

    render() {
        if (this.state.noAccess) {
            return (
                <div style={{ fontSize: '20px', fontWeight: 'bold', textAlign: 'center', padding: '30px' }}>Sorry, you do not have access to this course.</div>
            )
        }
        const sectionCompletion = this.state.currentModuleIndex === this.state.course.sections[this.state.currentSectionIndex].modules.length - 1 ? true : false;
        const courseCompletion = this.state.currentModuleIndex === this.state.course.sections[this.state.course.sections.length - 1].modules.length - 1 ? true : false;
        const isQuizModule = this.state.course.sections[this.state.currentSectionIndex].modules[this.state.currentModuleIndex].isQuizModule == 1 ? true : false;
        const isCourseCompleted = this.state.course.userStatus == 2
        const isCourseDeleted = this.state.course.isCourseDeleted
        return (
            <div>
                {this.state.showMainPage &&
                    <div className='main-container-course'>
                        <div className='course-header'>
                            <Link to={`/${this.state.course.pathName}`} style={{ margin: '10px' }}><div className="header-logo" style={{ backgroundImage: `url(${config.spacesURL}/${this.state.course.hubLogoId})` }} />
                            </Link>
                            <div className='header-title-container'>
                                <div className='header-course-title'>{this.state.course.title}</div>
                                <div className='header-hub-title'>{this.state.course.hubName}</div>
                            </div>
                            {this.state.course.hubUserAccessLevel >= hubAccessLevels.HUB_ADMIN && !this.state.course.isCourseDeleted &&
                                <div className="action-button-container-top">
                                    <Button className="action-button module" onClick={e => this.onEditModule(e)}>Edit</Button>
                                    <Button className="action-button warning" onClick={e => this.openDeleteModal(e)}>Delete</Button>
                                </div>
                            }
                        </div>
                        <div className='course-description-container'>
                            <div className='course-description-title'>Course Description</div>
                            <p className='course-description'>{this.state.course.description}
                            </p>
                        </div>
                        <div className='learning-outcome'>
                            {
                                this.state.course.learningOutcomes.map(
                                    (learningOutcome, index) => (
                                        <div className='learning-outcome-container' key={index}>
                                            <div style={{ color: '#14B4C8', fontWeight: 'bold', fontSize: '20px' }}>Learning Outcome {index + 1}. </div>
                                            <div style={{ fontSize: '20px' }}>{learningOutcome}</div>
                                        </div>
                                    )
                                )
                            }
                        </div>
                        {/* <div className='time-display-container'>
                            It should take you approximately <p style={{ fontWeight: 'bold' }}>1 hour </p>to complete this course.
                        </div> */}
                        <div className='start-course-button-container'>
                            <Button className='start-course-button' onClick={() => { this.onClickStartCourse() }}>{this.state.progress <= 0 ? 'Start' : 'Continue'} Course</Button>
                        </div>
                        <div className='section-display-container'>
                            {
                                this.state.course.sections.map(
                                    (eachSection, sectionIndex) => {
                                        return <div key={eachSection.id}>
                                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{eachSection.title}</div>
                                            <hr />
                                            <table className='module-table'>
                                                <tbody>
                                                    {
                                                        eachSection.modules.map((eachModule, moduleIndex) => {
                                                            const isActive =
                                                                sectionIndex === this.state.currentSectionIndex &&
                                                                moduleIndex === this.state.currentModuleIndex;
                                                            const handleModuleClick = () => {
                                                                if (eachModule.completionStatus) {
                                                                    this.setState({
                                                                        currentSectionIndex: sectionIndex,
                                                                        currentModuleIndex: moduleIndex,
                                                                        showMainPage: false,
                                                                        showModulePage: true,
                                                                    })
                                                                }
                                                            }
                                                            return <tr key={eachModule.moduleId}
                                                                className={isActive ? 'active-module' : ''}
                                                                style={isActive ? { cursor: 'pointer', backgroundColor: '#f0f8ff' } : { cursor: 'pointer' }}
                                                                onClick={handleModuleClick} >
                                                                <td style={{ width: '10%' }}><img src={`${config.spacesURL}/${eachModule.moduleLogoId}`} style={{ width: '30px', height: '30px' }}></img> </td>
                                                                <td style={{ width: '80%' }}>{eachModule.moduleName}</td>
                                                                <td style={{ width: '10%' }}>
                                                                    <Checkbox className='checkbox' name='moduleCheckBox' checked={eachModule.completionStatus} /></td>
                                                            </tr>
                                                        }
                                                        )
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                )
                            }
                        </div>
                        <div className='start-course-button-container'>
                            <Button className='start-course-button' onClick={() => { this.onClickStartCourse() }}>{this.state.progress <= 0 ? 'Start' : 'Continue'} Course</Button>
                        </div>
                    </div>

                }
                {this.state.showModulePage && <div className='course-module-page'>
                    <div className='course-module-side-bar'>
                        <div className='progress-bar-container'>
                            <ProgressBar now={this.state.progress} />
                            <div style={{ color: "#14B4C8", textTransform: "uppercase", marginTop: '3px', fontWeight: 'bold' }}>{this.state.progress}% completed</div>
                        </div>
                        <hr />
                        <div className='section-display-container' style={{ padding: '10px' }}>
                            {this.state.course.sections.map((eachSection, sectionIndex) => (
                                <div key={eachSection.id}>
                                    <div style={{ fontSize: '15px', fontWeight: 'bold', paddingTop: '25px' }}>{eachSection.title}</div>
                                    <hr />
                                    <table className='module-table'>
                                        <tbody>
                                            {eachSection.modules.map((eachModule, moduleIndex) => {
                                                const isActive =
                                                    sectionIndex === this.state.currentSectionIndex &&
                                                    moduleIndex === this.state.currentModuleIndex;
                                                const handleModuleClick = () => {
                                                    if (eachModule.completionStatus) {
                                                        this.setState({
                                                            currentSectionIndex: sectionIndex,
                                                            currentModuleIndex: moduleIndex
                                                        })
                                                    }
                                                }

                                                return (
                                                    <tr
                                                        key={eachModule.moduleId}
                                                        className={isActive ? 'active-module' : ''}
                                                        style={isActive ? { cursor: 'pointer', backgroundColor: '#f0f8ff' } : { cursor: 'pointer' }}
                                                        onClick={handleModuleClick}
                                                    >
                                                        <td style={{ width: '15%' }}>
                                                            <img src={viewModuleImg} alt='Module Icon' />
                                                        </td>
                                                        <td style={{ width: '70%' }}>{eachModule.moduleName}</td>
                                                        <td style={{ width: '15%' }}>
                                                            <Checkbox
                                                                className='checkbox'
                                                                name='moduleCheckBox'
                                                                checked={eachModule.completionStatus}
                                                                readOnly
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ))}
                        </div>

                    </div>
                    <div className='course-module-main-page'>
                        <div><Module moduleId={this.state.course.sections[this.state.currentSectionIndex].modules[this.state.currentModuleIndex].moduleId}
                            quizCompleted={() => this.quizCompleted()} moduleRendered={() => this.moduleRendered()}
                            courseData = {{
                                id: this.state.course.id,
                                logoId: this.state.course.logoId
                            }}></Module></div>
                        {!sectionCompletion && this.state.moduleLoaded && <div className='buttons-container'>
                            <Button onClick={() => (this.handlePrevious())}>Previous</Button>
                            <Button onClick={() => { this.onClickHome() }}>Home</Button>
                            <Button onClick={() => { this.handleNext() }}>Next</Button>
                        </div>}
                        {
                            sectionCompletion && this.state.moduleLoaded && (
                                (isQuizModule && this.state.quizCompletion) || !isQuizModule
                            ) && (<div className='buttons-container' style={{ justifyContent: 'center' }}>
                                <Button onClick={() => { this.handleNext() }} disabled={(courseCompletion && isCourseCompleted) || (courseCompletion && isCourseDeleted)}>Complete {courseCompletion ? 'Course' : 'Section'}</Button>
                            </div>)
                        }

                    </div>
                </div>
                }
                <Modal show={this.state.showModal && this.state.showDeleteModal} onHide={this.closeModal}>
                    <Modal.Header closeButton />
                    <div className="module-modal-container">
                        <div>
                            <h3>Delete Course</h3>
                        </div>
                        <div>
                            <p>Are you sure you wish to continue?</p>
                        </div>
                        <div className="button-container">
                            <Button onClick={() => this.onDeleteCourse()}>Yes</Button>
                            <Button onClick={() => this.closeModal()}>No</Button>
                        </div>
                    </div>
                </Modal>

                <Modal show={this.state.showModal && this.state.showCongratulationsModal} onHide={this.closeModal}>
                    <Modal.Header closeButton />
                    <div className="module-modal-container">
                        <div>
                            <h3>Congratulations!</h3>
                        </div>
                        <div>
                            <p>{`You have successfully completed the course - ${this.state.course.title}`}</p>
                        </div>
                    </div>
                </Modal>
            </div>

        )
    }
}

export default withRouter(checkStatus(Course));