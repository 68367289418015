import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import './Create.css';
import infoIcon from '../../resources/img/info-icon-2.png';
import CreateModule from './CreateModule';
import CreatePlanSession from './CreatePlanSession';
import CreateCourse from './CreateCourse';
import CreateQuiz from './CreateQuiz.js';
import { Button } from 'react-bootstrap';

export class Create extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModule: false,
            showButtons: true,
            showFolder: false,
            showSession: false,
            showCourse: false,
            showQuiz: false,
            showForum: false,

        }
    }
    //hubId={this.state.hub.id} approved={this.state.hub.approved} user={this.props.user}
    render() {

        const currentPath = this.props.location.pathname;
        return (

            <div className='main-div'>

                {this.state.showButtons && <div className="create-section"><h1>Create</h1></div>}
                <div className='create-body'>
                    {this.state.showButtons &&
                        <div className='box'>
                            <div className='create-item'>
                                <Button
                                    onClick={() => { this.setState({ showModule: true, showButtons: false }) }
                                    }>
                                    Information Module
                                </Button>
                            </div>
                            <div className='info-container'>
                                <img className='info-icon' src={infoIcon} alt='help icon' />
                                <span className='hover-text'>An Information Module typically contains information on a specific topic, in addition to support materials, reports, and evaluations. This can be shared in various types of content, such as text, images, and videos.</span>
                            </div>
                        </div>}
                    {this.state.showButtons && <div className='box'>
                        <div className='create-item'>
                            <Button
                                onClick={() => { this.setState({ showFolder: true, showButtons: false }) }
                                }>
                                Folder
                            </Button>
                        </div>
                        <div className='info-container'>
                            <img className='info-icon' src={infoIcon} alt='help icon' />
                            <span className='hover-text'>A folder is a group of modules that has its own title and description and appears on the Home tab of a Hub. Folders can be useful when you wish to provide users with easy access to a group of Modules, for example, a volunteer induction or training session.</span>
                        </div>
                    </div>}
                    {this.state.showButtons && <div className='box'>
                        <div className='create-item'> <Button
                            onClick={() => { this.setState({ showSession: true, showButtons: false }) }
                            }>
                            Session
                        </Button></div>
                        <div className='info-container'>
                            <img className='info-icon' src={infoIcon} alt='help icon' />
                            <span className='hover-text'>A Session is a Folder that has a location, date and Hub user(s) allocated to it. The Sessions appear in the Sessions tab of relevant Hub Users profiles. Creating Sessions can be useful when you want specific Modules to be used before, during and/or to support the delivery of a training or activity session.</span>
                        </div>
                    </div>}
                    {this.state.showButtons && <div className='box'>
                        <div className='create-item'> <Button
                            style={{ backgroundColor:'#ACACAC'}}>
                            Forum
                        </Button></div>
                        <div className='info-container'>
                            <img className='info-icon' src={infoIcon} alt='help icon' />
                            <span className='hover-text'>This feature is coming soon!</span>
                        </div>
                    </div>}
                    {this.state.showButtons && <div className='box'>
                        <div className='create-item'> <Button
                            onClick={() => {
                                this.setState({ showQuiz: true, showButtons: false })
                            }
                            }
                            disabled={!this.props.elearningApproved}
                            style={{ backgroundColor: this.props.elearningApproved ? '#14B4C8' : 'grey' }}
                        >
                            Quiz Module
                        </Button></div>
                        <div className='info-container'>
                            <img className='info-icon' src={infoIcon} alt='help icon' />
                            <span className='hover-text'>{this.props.elearningApproved ? `A Quiz Module allows you to add questions to your module in order to test a user's learning. There are multiple formatting options for the quizzes including:
Multiple choice 
Check box
Buttons
Fill the blank
Drag and Drop`:
                                'Quiz Modules are a premium feature. For more information on how to access this feature, please contact us at support@actify.org.uk.'}</span>
                        </div>
                    </div>}
                    {this.state.showButtons && <div className='box'>
                        <div className='create-item'> <Button
                            onClick={() => { this.setState({ showCourse: true, showButtons: false }) }
                            }
                            disabled={!this.props.elearningApproved}
                            style={{ backgroundColor: this.props.elearningApproved ? '#14B4C8' : 'grey' }}>
                            eLearning Course
                        </Button></div>
                        <div className='info-container'>
                            <img className='info-icon' src={infoIcon} alt='help icon' />
                            <span className='hover-text'>{this.props.elearningApproved ? 'You can group Information Modules and Quiz Modules together in sections in order to create an eLearning Course. Courses can be assigned to Groups and given a deadline.' :
                                'eLearning Courses are a premium feature. For more information on how to access this feature, please contact us at support@actify.org.uk.'}</span>
                        </div>
                    </div>}

                </div>
                <div>
                    {this.state.showModule && <CreateModule hubId={this.props.hubId} approved={this.props.approved} user={this.props.user} history={this.props.history} category={1} onClickBack={() => this.setState({ showButtons: true, showModule: false })} edit={false} />}
                </div>
                <div>
                    {this.state.showFolder && <CreatePlanSession hubId={this.props.hubId} approved={this.props.approved} users={this.props.users} modules={this.props.modules} user={this.props.user} createType={'Folder'} history={this.props.history} onClickBack={() => this.setState({ showButtons: true, showFolder: false })} />}
                </div>
                <div>
                    {this.state.showSession && <CreatePlanSession hubId={this.props.hubId} approved={this.props.approved} users={this.props.users} modules={this.props.modules} user={this.props.user} createType={'Session'} history={this.props.history} onClickBack={() => this.setState({ showButtons: true, showSession: false })} />}
                </div>
                <div>
                    {this.state.showCourse && <CreateCourse hubId={this.props.hubId} modules={this.props.modules} approved={this.props.approved} user={this.props.user} history={this.props.history} onClickBack={() => this.setState({ showButtons: true, showCourse: false })} />}
                </div>
                <div>
                    {this.state.showQuiz && <CreateModule hubId={this.props.hubId} approved={this.props.approved} user={this.props.user} history={this.props.history} category={3} onClickBack={() => this.setState({ showButtons: true, showQuiz: false })} edit={false} />}
                </div>
            </div>



        )
    }

}

export default withRouter(Create);