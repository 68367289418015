import React, { Component } from 'react';
import Dotdotdot from 'react-dotdotdot';
import { Button, Row, Col } from 'react-bootstrap';
import './CoursesTab.css';
import config, { hubAccessLevels } from '../../constant/config';
import { Link } from 'react-router-dom';

export class CoursesTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCourses: true,
            showGroupInfo: false,
            showGroupDetails: false,
            courses: []
        }
    }

         async componentDidMount(){
          const res = await fetch(`${config.apiURL}/hub/${this.props.hub.id}/getAllCourseDetails?userId=${encodeURIComponent(this.props.user.id)}`, {
              method: 'GET',
              credentials: 'include',
              headers: { 'content-type': 'application/json' },
            });
            if (res.status == 200) {
              const coursesInHub = await res.json(); 
              console.log(coursesInHub)
              this.setState({
                courses: coursesInHub,
              });
            }
        }


    renderGroupDetailsTable() {
        const userDetails = this.state.selectedGroup.users
        return userDetails.map(user => (
            <tr key={user.userId}>
                <td style={{ textAlign: 'center' }}><div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${user.avatarId})` }} /></td>
                <td style={{ textAlign: 'center' }}>{user.name}</td>
                <td style={{ textAlign: 'center' }}>{user.progress}</td>
            </tr>
        )

        )
    }


    renderGroupsTable() {
        const selectedCourseGroups = this.state.selectedCourse.groupsDetails
        return selectedCourseGroups.map(eachGroup => (
            <tr key={eachGroup.groupId}>
                <td style={{ textAlign: 'center' }}><div className='group-name' onClick={() => { this.setState({ showGroupDetails: true, selectedGroup: eachGroup, showGroupInfo: false }) }}>{eachGroup.groupName}</div></td>
                <td style={{ textAlign: 'center' }}>{eachGroup.noOfUsers}</td>
                <td style={{ textAlign: 'center' }}>{eachGroup.completions}</td>
                <td style={{ textAlign: 'center' }}>{eachGroup.deadLine}</td>
            </tr>
        )

        )
    }

    renderCourses(tile, index) {
        const data = {hub: this.props.hub, course: tile};
        return <div className='tile-container'>
            <Link to={{pathname:`/course/${tile.courseId}`, state:data}} className="tile-image-container" style={{ backgroundImage: `url(${config.spacesURL}/${tile.logoId})`  }} />
                <div className="module-name-container" >
                    <Link to={{pathname:`/course/${tile.courseId}`, state:data}} className="plan-name" style={{color:'#646464'}} >
                        <Dotdotdot clamp={2}>
                            {tile.courseName}
                        </Dotdotdot>
                    </Link>
                </div>
                <div className="hub-name-container">
                    <div className="tile-hub-image" style={{ backgroundImage: `url(${config.spacesURL}/${this.props.hub.logoId}.thumbnail)` }} />
                    <h4 className="hub-name" style={{color:'#A5A3A3'}}><Link to={`/${this.props.hub.pathName}`} >{this.props.hub.name}</Link></h4>
                </div>
                {this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN && 
                <div className="buttons-container">
                    <Button onClick={() => { this.setState({ selectedCourse: tile, showGroupInfo: true, showCourses: false }) }}>Group Info</Button>
                    {/* <Button onClick={() => { }}>Download Report</Button> */}
                </div>
    }
        </div>
    }
    render() {
        return (
            <div className='main-container-courses'>
                {this.state.showCourses &&
                    <div className='list-courses-container'>
                        <div className="home-tab-container">
                            {<Row className="section-container">
                                <div className='main-heading'>Courses</div>
                                <Col className="content-container">
                                    <div className='tiles-container'>
                                        {this.state.courses.map((tile, index) => this.renderCourses(tile, index))}
                                    </div>
                                </Col >

                            </Row >
                            }
                        </div>
                    </div>
                }
                {this.state.showGroupInfo &&
                    <div className='group-info-container'>
                        <div className='sub-header-container'>
                            <div className='main-heading'>{this.state.selectedCourse.courseName}</div>
                            <div className='header-back-button-container'> <Button className='back-button' onClick={() => { this.setState({ showGroupInfo: false, showCourses: true }) }}>Back</Button></div>
                        </div>
                        <div className='list-courses-table-container'>
                            <table className='courses-table'>
                                <thead>
                                    <tr>
                                        <th>Group</th>
                                        <th>Number of Users</th>
                                        <th>Completions</th>
                                        <th>Deadline</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.renderGroupsTable()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    this.state.showGroupDetails &&
                    <div className='group-details-container'>
                        <div className='sub-header-container'>
                            <div className='main-heading'>{this.state.selectedGroup.groupName}</div>
                            <div className='header-back-button-container'> <Button className='back-button' onClick={() => { this.setState({ showGroupInfo: true, showGroupDetails: false }) }}>Back</Button></div>
                        </div>
                        <div className='list-courses-table-container'>
                            <table className='courses-table'>
                                <thead>
                                    <tr>
                                        <th>Avatar</th>
                                        <th>Name</th>
                                        <th>Progress</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.renderGroupDetailsTable()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }

            </div>
        )
    }
}
export default CoursesTab;