import React, { Component } from 'react';
import { Image, Button } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import config from '../../constant/config';
import Quiz from '../common/quiz/Quiz';
import './UserLearningStats.css';

class UserLearningStats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user,
      quiz: null,
      viewQuiz: false,
    };
  }

  onClickMoudle(id) {
    this.props.history.push(`/module/${id}`);
  }

  onClickBack(){
    this.props.onClickBack();
  }

  async onViewQuiz(userResult) {
    const res = await fetch(`${config.apiURL}/quiz/${userResult.moduleId}`, {
      method: 'GET',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
    });
    if (res.status !== 200) {
      console.error('unable to fetch module questions');
    }
    const questions = await res.json();
    this.setState({
      quiz: {
        questions,
        moduleId: userResult.moduleId,
        userResult,
      },
      viewQuiz: true,
    });
  }

  renderTableRows() {
    return this.props.userResults.map((userResult) => {
      return (
        <tr key={userResult.courseId}>
          <td>{userResult.courseName}</td>
          {/* <td>{userResult.courseProgress}</td> */}
          <td>{userResult.courseStatus}</td>
          <td>{userResult.courseDeadline}</td>
          <td>{userResult.userCompletionDate}</td>
        
        </tr>
      );
    });
  }

  render() {
    return (
      <div className="users-stats-container">
        <div className="user-header-stat" >
          <div className="avatar-container">
            <Image className="avatar-image" src={`${config.spacesURL}/${this.props.user.avatarId}`} />
          </div>
          <div className="user-name">{`${this.props.user.firstName} ${this.props.user.surname}`}</div>
        <div className ='back-button-container'><Button onClick={() =>  this.onClickBack()}>Back</Button></div>
        </div>
        <table className="users-table">
          <thead >
            <tr>
              <th > Name</th>
              {/* <th >Progress </th> */}
              <th >Status</th>
              <th> Deadline</th>
              <th>Completion Date</th>            
            </tr>
          </thead>
          <tbody>
            { this.renderTableRows()}
            {/* {this.state.viewQuiz ?
              <div className="quiz-container">
                <Quiz
                  questions={this.state.quiz.questions}
                  moduleId={this.state.quiz.moduleId}
                  reviewResult={this.state.quiz.userResult}
                  userId={this.state.user.id}
                />
              </div> : */}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(UserLearningStats);
