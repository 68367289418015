import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { hubAccessLevels } from '../../constant/config';
import { dateCompare } from '../../lib/utilities';
import Section from './Section';
import './HomeTab.css';


class HomeTab extends Component {

  getNoModuleContent() {
    if (this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      return (
        <div className="no-modules">
          <div className="no-module-text">
          This hub does not have any modules yet, to get started create your first module in the Create tab above.
          </div>
          {/* <Button
            className="create-module-button"
            onClick={() => this.props.changeTab(4)}
          >
            Create Module
          </Button> */}
        </div>
      );
    }
    return (
      <div className="no-modules">
        {this.props.user ? 'It looks like the hub admins have not added any modules yet. Please check back later.'
          : 'It looks like the hub admins have not added any public modules yet. Please check back later.'}
      </div>
    );
  }

  render() {
    const modulesFiltered = this.props.modules.sort(dateCompare);

    const informationModulesFiltered = modulesFiltered.filter((eachModule) => eachModule.categoryId !== 3);

    const quizModulesFiltered = modulesFiltered.filter(((eachModule) => eachModule.categoryId === 3));

    return (
      <div className="home-tab-container">
        {!this.props.modules.length > 0 && this.getNoModuleContent()}
        {modulesFiltered.length > 0 && <Section context="modules" title="All Information Modules" tiles={informationModulesFiltered} small={true} moduleTab={true} />}
        {this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN && modulesFiltered.length > 0 && <Section context="modules" title="All Quiz Modules" tiles={quizModulesFiltered} small={true} moduleTab={true} />}
      </div>
    );
  }
}

export default HomeTab;
