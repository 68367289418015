import React, { Component } from 'react';
import { Button, FormControl, FormGroup, ControlLabel, HelpBlock, Image } from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import config from '../../constant/config';
import './AddUser.css';

class AddUsersToGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernames: '',
      validUsernames: true,
      userOptions: this.props.hubUsers,
      userUpdated: false,
      users: [], // Store selected users for invitation
    };
    this.renderMenuItemChildren = this.renderMenuItemChildren.bind(this);
  }

  onUserSelect(users) {
    this.setState({ users });
  }


   addUsersToGroup() {
    const { addUser } = this.props;
    if (this.state.users.length > 0) {
      for (const user of this.state.users) 
        {
            console.log(user)
            addUser(user)
        // try {
        //     // change the api to new one, which addes the users to group
        //   const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/invite`, {
        //     method: 'POST',
        //     credentials: 'include',
        //     headers: { 'content-type': 'application/json' },
        //     body: JSON.stringify({ inviteeId: user.id }),
        //   });
        //   if (res.status === 200) {
        //     this.setState({ userUpdated: true, user: null });
        //   }
        // } catch (err) {
        //   this.setState({ emailExternalResponse: err.toString() });
        // }
      }
    }
  }

  handleUserSearch(query) {
    this.setState({ userUpdated: false });
    // if (query) {
    //   fetch(`${config.apiURL}/user/search/${query}`, {
    //     method: 'PUT',
    //     credentials: 'include',
    //     headers: { 'content-type': 'application/json' },
    //     body: JSON.stringify({ hubId: this.props.hubId }),
    //   })
    //     .then(resp => resp.json())
    //     .then(json => this.setState({ userOptions: json, userUpdated: false }));
    // }
  }

  renderMenuItemChildren(option) {
    return (
      <div className="search-options">
        <Image className="avatar-image" src={`${config.spacesURL}/${option.avatarId}`} />
        <div className="name-container">{`${option.firstName} ${option.surname}`}</div>
      </div>
    );
  }

  render() {
    return (
      <div className="add-user-container">
        {!this.state.userUpdated &&
          <div>
            <FormGroup className="form" controlId="formUsernames" validationState={(!this.state.validUsernames) && 'error'}>
              <ControlLabel><strong>Add existing Hub Members by entering their usernames below. You can add multiple users by selecting each from the suggestions as you type.</strong></ControlLabel>
              <AsyncTypeahead
                className="typeahead__container"
                labelKey={option => `${option.firstName} ${option.surname}`}
                filterBy={['firstName', 'surname', 'emailAddress']}
                onSearch={query => this.handleUserSearch(query)}
                searchText="Searching..."
                emptyLabel="No Users"
                minLength={2}
                options={this.state.userOptions}
                onChange={e => this.onUserSelect(e)}
                useCache={false}
                renderMenuItemChildren={this.renderMenuItemChildren}
                multiple
                placeholder="Enter usernames separated by commas or select from suggestions"
              />
              {!this.state.validUsernames && <HelpBlock>Please enter valid usernames</HelpBlock>}
            </FormGroup>
            <div className="button-container">
              <Button className="adduser-button" onClick={() => this.addUsersToGroup()}>Add Users</Button>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default AddUsersToGroup;
