import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import config, { hubAccessLevels } from '../../constant/config';
import UserDetails from './UserDetails';
import AddUser from './AddUser';
import EditPermissions from './EditPermissions';
import UserLearningStats from './UserLearningStats';
import RemoveUser from './RemoveUser';
import './UsersTab.css';

class UsersTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: props.users,
      modalUser: '',
      modalContent: null,
      roles: [],
      userAccessLevel: 1,
      modalMessageState: null,
      isShow: false,
      showModal:false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleSavePermissions = this.handleSavePermissions.bind(this);
    this.onSendMessage = this.onSendMessage.bind(this);
    this.createText = this.createText.bind(this);
    this.condrender = this.condrender.bind(this);
  }

  componentWillMount() {
    this.getPermissions();
  }

  onAddUserClick() {
    this.setState({
      showModal: true, modalContent: <AddUser hubId={this.props.hubId} />,
    });
  }

  async onEditPermissionsClick(userId) {
    await this.getUserAccessLevel(userId);
    this.setState({
      showModal: true,
      modalContent: <EditPermissions
        handleSavePermissions={(id, accesslvl) => this.handleSavePermissions(id, accesslvl)}
        userToEdit={userId}
        currentAccess={this.state.userAccessLevel}
        accessLevels={this.state.roles}
        messageState={this.state.modalMessageState}
        showMessage={true}
      />
    });
  }

  onSendMessage(user) {
    this.setState({
      showModal: true,
      modalContent: <UserDetails admin={this.props.accessLevel >= hubAccessLevels.HUB_ADMIN} modalUser={user} sendMessage />,
    });
  }


  onUserClick(user) {
    this.setState({
      showModal: true,
      modalContent: <UserDetails admin={false} modalUser={user} />,
    });
  }

  async onLearningClick(user) {
    try {
      //need to change the url
      const res = await fetch(`${config.apiURL}/course/${user.id}/hub/${this.props.hubId}`, {
        method: 'GET',
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
      });
      if (res.status !== 200) {
        throw new Error('unable to fetch results');
      }
      else{
        const userResults = await res.json();
        console.log(userResults)
        this.setState({
          learningStatsUser: user,
          userResults,
          showLearningStats: true,
        });
      }
      
    } catch (err) {
      console.error(err.message);
    }
  }

  async getPermissions() {
    try {
      const res = await fetch(`${config.apiURL}/hub/userRoles`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const roles = await res.json();
        const i = roles.findIndex(role => role.role === 'follower');
        roles.splice(i, 1);
        this.setState({ roles });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getUserAccessLevel(userId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/${userId}/accesslevel`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const accessRes = await res.json();
        const userAccessLevel = accessRes.accessLevel;
        this.setState({ userAccessLevel });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async handleSavePermissions(userId, accesslevel) {
    try {
      console.log(accesslevel);
      const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/${userId}/accesslevel`, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          accessId: accesslevel,
        }),
      });
      if (res.status === 200) {
        const accessRes = await res.json();
        const userAccessLevel = accessRes.accessLevel;
        this.setState({
          userAccessLevel,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async removeHubUser(user) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/user`, {
        credentials: 'include',
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          id: user.id,
        }),
      });
      if (res.status === 200) {
        const { users } = this.state;
        const userIndex = this.state.users.findIndex(u => u.id === user.id);
        users.splice(userIndex, 1);
        this.setState({
          users,
          showModal: false,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  renderRemoveConfirm(user) {
    this.setState({
      showModal: true,
      modalContent: <RemoveUser
        modalUser={user}
        closeModal={() => this.closeModal()}
        removeUser={() => this.removeHubUser(user)}
      />,
    });
  }

  getRoleText(accessLevel) {
    switch (accessLevel) {
      case hubAccessLevels.HUB_OWNER:
        return 'Owner';
      case hubAccessLevels.HUB_ADMIN:
        return 'Admin';
      case hubAccessLevels.HUB_MEMBER:
        return 'Member';
      default:
        return '-';
    }
  }

  closeModal() {
    this.setState({ showModal: false, modalContent: null, modalMessageState: null });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  handleChange (e) {
    this.setState({value: e.target.value});
  };

  createText() {
    this.setState({ isShow: !this.state.isShow});
   }

    condrender() {
      return (
        <p style={{color: "var(--colour-accent-1)"}}>User permissions successfully updated. Please refresh to see the changes. </p>
      );
    }

  renderTableRows() {
    const { users } = this.state;
    const uniqueUsers = [];
    const uniqueIds = []; // store users ids
    for (let i = 0; i < users.length; i++) {
      if (!uniqueIds.includes(users[i].id)) {
        uniqueUsers.push(users[i]);
        uniqueIds.push(users[i].id)
      }
    }
    return uniqueUsers.map(user => (
      <tr key={user.id}>
        <td><div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${user.avatarId})` }} /></td>
        <td><div className="user-name" onClick={() => { this.onUserClick(user); }}>{`${user.firstName} ${user.surname}`}</div></td>
        <td>
          {/*<div style={{display: "flex"}}>
            {this.getRoleText(user.accessLevel)}
            <div className='facaretdown' onClick={() => this.onEditPermissionsClick(user.id)}>
              {this.props.accessLevel >= hubAccessLevels.HUB_OWNER &&
              <IconContext.Provider value={{ style: { color: "#646464", verticalAlign: "middle", paddingBottom: "8px"} }}>
                <FaCaretDown size={'2em'}  />
              </IconContext.Provider>}
            </div>
              </div>*/}
          <div style={{display: "flex"}}>
            <div style={{marginTop: "3px"}}>
              {this.getRoleText(user.accessLevel)}
            </div>
            <div style={{paddingLeft: "5px"}}>
              {this.props.accessLevel >= hubAccessLevels.HUB_ADMIN &&
              <select style={{border: "none", width: "18px"}} onChange={(e) => {this.handleSavePermissions(user.id, parseInt(e.target.value, 10)); this.createText()}}>
                <option value="" style={{fontSize: "1px"}} />
                <option value="2">Member</option>
                <option value="3">Admin</option>
                <option value="4">Owner</option>
              </select>}
              <p>{this.state.value}</p>
            </div>
          </div>
        </td>
        <td><Button onClick={() => this.onSendMessage(user)}>Send Message</Button></td>
        <td>{this.props.accessLevel >= hubAccessLevels.HUB_ADMIN &&
          <Button onClick={() => this.onLearningClick(user)}>
            Courses
          </Button>}
        </td>
        <td>{this.props.accessLevel >= hubAccessLevels.HUB_OWNER &&
          <div className="warning-button-container">
            <Button className="warning-button" onClick={() => this.renderRemoveConfirm(user)}>Remove User</Button>
          </div>}
        </td>

      </tr>
    ));
  }

  render() {
    return (
      <div>
        {!this.state.showLearningStats &&
        <div className="users-tab-container">
          <table className="users-table">
            <thead>
              <tr>
                <th>Avatar</th>
                <th>Name</th>
                <th className="role-heading">Role</th>
                <th />
                <th />
                <th><div className="button main">{this.props.accessLevel >= hubAccessLevels.HUB_ADMIN && <Button className='submit-button' onClick={() => this.onAddUserClick()}>Add User+</Button>}</div></th>
              </tr>
            </thead>
            <tbody>
              {this.renderTableRows()}
            </tbody>
          </table>
          <div style={{textAlign: "center"}}>{this.state.isShow ? this.condrender() : null}</div>
        </div>}
        {this.state.showLearningStats &&
          <UserLearningStats onUserClick={user => this.onUserClick(user)} user={this.state.learningStatsUser} userResults={this.state.userResults} modules={this.props.modules} onClickBack={() => this.setState({ showLearningStats: false })} />
        }
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {this.state.modalContent}
        </Modal>
      </div>
    );
  }
}
export default UsersTab;
