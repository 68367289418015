import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { hubAccessLevels } from '../../constant/config';
import { dateCompare } from '../../lib/utilities';
import Section from './Section';
import './HomeTab.css';


class HomeTab extends Component {

  getNoModuleContent() {
    if (this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      return (
        <div className="no-modules">
          <div className="no-module-text">
          This hub does not have any folders yet, to get started create your first folder in the Create tab above.
          </div>
          {/* <Button
            className="create-module-button"
            onClick={() => this.props.changeTab(5)}
          >
            Create Folder
          </Button> */}
        </div>
      );
    }
    return (
      <div className="no-modules">
        {this.props.user ? 'It looks like the hub admins have not added any folders yet. Please check back later.'
        : 'It looks like the hub admins have not added any public folders yet. Please check back later.'}
      </div>
    );
  }

  render() {
    const plansFiltered = this.props.plans.sort(dateCompare);
    return (
      <div className="home-tab-container">
        {!this.props.plans.length > 0 && this.getNoModuleContent()}
        {plansFiltered.length > 0 && <Section context="plans" title="All Folders" tiles={plansFiltered} small={true} hub={this.props.hub} />}
      </div>
    );
  }
}

export default HomeTab;
