import React, { Component } from 'react';
import {
  Row, Col, Button, FormGroup, ControlLabel, FormControl,
  HelpBlock, Checkbox
} from 'react-bootstrap';
import config from '../../constant/config';
import QuizBuilder from './quiz/QuizBuilder';
import './CreateModule.css';
import ModuleMembers from './ModuleMembers';
import tick from "../../resources/img/Vector.svg";
import publicIcon from "../../resources/img/publicicon1.svg";
import HiddenIcon from "../../resources/img/HiddenIcon.svg";
import eyeVisibleIcon from "../../resources/img/eyeVisibleicon.svg";
import loadingAnimation from "../../resources/img/loading_animation.gif";
import CourseIcon from "../../resources/img/course.svg";

import RTE from './RTE';

import RichTextEditor, {
  getTextAlignBlockMetadata,
  getTextAlignStyles,
} from 'react-rte';

const fetchCategories = async () => {
  const res = await fetch(`${config.apiURL}/category`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch categories');
  }
  const body = await res.json();
  return body;
};

const fetchTargetGroups = async () => {
  const res = await fetch(`${config.apiURL}/targetGroup`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch target groups');
  }
  const targetGroups = await res.json();
  return targetGroups;
};

const fetchActivities = async () => {
  const res = await fetch(`${config.apiURL}/activity`, { credentials: 'include' });
  if (res.status !== 200) {
    throw new Error('could not fetch activities');
  }
  const activities = await res.json();
  return activities;
};

class CreateModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.moduleTitle || '',
      validTitle: true,
      category: this.props.category,
      description: '',
      validDescription: true,
      textContent: RichTextEditor.createEmptyValue(),
      validTextContent: true,
      accessId: '',
      validAccessId: true,
      categories: '',
      categoriesList: [],
      featuredImage: '',
      featuredImageFileType: '',
      featuredImageTitle: '',
      validImage: true,
      uploadedFiles: [],
      embeddedVideos: [],
      videoEmbedLink: '',
      videoEmbedLinkError: '',
      pdfCount: 0,
      imageCount: 0,
      assetUploadError: '',
      activities: [],
      activitiesSelected: [],
      activityOptions: [],
      targetGroups: [],
      targetGroupsSelected: [],
      targetGroupOptions: [],
      showFilter: false,
      showActivitiesFilter: false,
      showGroupsFilter: false,
      quizQuestions: [],
      membersOld: [],
      members: [],
      userMember: false,
      published: false,
      errorMessage: null,
      commentsEnabled: false,
      featuredImage1: null,
      // states for the text editor
      img: null,
      type: null,
      filename: null,
      selectedActivity: '',
      selectedTarget: '',
      showModal: false,
      hubMembers: [],
      selectedResitsThresh:999,
      selectedCorrectAnsThresh:0,

    };
    this.addQuizQuestion = this.addQuizQuestion.bind(this);
    this.editQuizQuestion = this.editQuizQuestion.bind(this);
    this.removeQuizQuestion = this.removeQuizQuestion.bind(this);
    this.onQuizQuestionsReorder = this.onQuizQuestionsReorder.bind(this)
    this.addMember = this.addMember.bind(this);
    this.removeMember = this.removeMember.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    console.log(this.props); // Check if history is present here
  }

  async componentWillMount() {
    this.getCategories();
    this.getSubCategories();
    try {
      this.getMembers(this.props.moduleId);
    }
    catch (err) {
      console.log(err);
    }
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  openModal() {
    this.setState({ showModal: true });
  }

  componentWillReceiveProps(props) {
    const hubId = this.props.hubId
    this.getHubMembers(hubId);
    if (!props.module) {
      return;
    }
    const { module } = props;
    const pdfs = [];
    const images = [];
    module.assets.forEach((asset) => {
      if (config.acceptedImageTypes.includes(asset.fileType)) {
        images.push(asset);
      }
      if (config.acceptedPdfTypes.includes(asset.fileType)) {
        pdfs.push(asset);
      }
    });
    this.setState({
      title: module.title,
      description: module.description,
      accessId: module.accessId,
      textContent: RichTextEditor.createValueFromString(module.textContent, 'html', { customBlockFn: getTextAlignBlockMetadata }),
      category: module.categoryId,
      uploadedFiles: module.assets,
      pdfCount: pdfs.length,
      imageCount: images.length,
      featuredImage: module.logoId,
      embeddedVideos: module.videos.map(video => (video.embedURL)),
      activitiesSelected: module.activities.map(a => a.id),
      targetGroupsSelected: module.targetGroups.map(t => t.id),
      quizQuestions: module.questions
        .sort((a, b) => {
          return a.questionOrder - b.questionOrder;
        }),
      commentsEnabled: module.commentsEnabled,
      selectedResitsThresh: module.selectedResitsThresh,
      selectedCorrectAnsThresh: module.selectedCorrectAnsThresh,

    });
  }

  async onPublishClick() {
    this.setState({
      published: true,
      errorMessage: "",
    });
    const validTitle = this.state.title.length > 0 && this.state.title.length < 255;
    const validDescription = this.state.description.length > 0 && this.state.description.length < 255;
    const validTextContent = this.state.textContent.toString('html', { blockStyleFn: getTextAlignStyles }).length > 2;
    const validAccessId = this.state.category == 3 ? true : this.state.accessId !== '';
    const validImage = !!this.state.featuredImage;
    this.setState({
      validTitle, validDescription, validTextContent, validAccessId, validImage,
    });
    if (!(validTitle && validDescription && validTextContent && validAccessId && validImage)) {
      this.setState({ errorMessage: 'One or more fields are invalid. The invalid fields are highlighted in red.' })
      this.setState({ published: false });
      return;
    }
    this.openModal();
    let newUploadIds = [];
    const newUploads = this.state.uploadedFiles.filter(file => file.data);
    for (let i = 0; i < newUploads.length; i += 1) {
      try {
        const payload = new Buffer(newUploads[i].data, 'binary').toString('binary');
        const assetRes = await fetch(`${config.apiURL}/asset/`, { // eslint-disable-line
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({
            data: payload,
            title: newUploads[i].title,
            fileType: newUploads[i].fileType,
          }),
        });
        const assetId = await assetRes.text(); // eslint-disable-line
        newUploadIds = [...newUploadIds, assetId];
      } catch (err) {
        console.log(err);
      }
    }
    let uploadedIds = this.state.uploadedFiles.filter(file => !file.data);
    uploadedIds = uploadedIds.map(uploadedAsset => uploadedAsset.id);
    uploadedIds = [...uploadedIds, ...newUploadIds];
    let logoId = this.state.featuredImage;
    if (typeof (this.state.featuredImage) !== 'string') {
      const payload = new Buffer(this.state.featuredImage, 'binary').toString('binary');
      const featuredImageResponse = await fetch(`${config.apiURL}/asset/`, {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          title: this.state.featuredImageTitle,
          data: payload,
          fileType: this.state.featuredImageFileType,
        }),
      });
      logoId = await featuredImageResponse.text();
    }
    const hubId = this.props.hubId
    const res = await fetch(`${config.apiURL}/hub/${hubId}/module/`, {
      credentials: 'include',
      method: this.props.edit ? 'PUT' : 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: this.props.module ? this.props.module.id : null,
        title: this.state.title,
        description: this.state.description,
        categoryId: parseInt(this.state.category, 10),
        textContent: this.state.textContent.toString('html', { blockStyleFn: getTextAlignStyles }),
        accessId: this.state.category == 3 ? 4 : this.state.accessId,//courseOnly aceess for Quiz
        activities: this.state.activitiesSelected.map(i => ({ id: i })),
        targetGroups: this.state.targetGroupsSelected.map(i => ({ id: i })),
        members: this.state.members.filter((el) => el.id !== this.props.user.id),
        membersOld: this.state.membersOld.filter((el) => el.id !== this.props.user.id),
        userMember: this.state.userMember,
        uploadedIds,
        logoId,
        videos: this.state.embeddedVideos,
        commentsEnabled: this.state.commentsEnabled,
      }),
    });
    if (res.status === 200) {
      const body = await res.json();
      if (parseInt(this.state.category, 10) === 3) {
        await this.onPublishQuiz(body.id);
      }

      this.props.history.push(`/module/${body.id}`);
      window.location.assign(`/module/${body.id}`);
    } else {
      // TODO: handle error on publishing module
    }
    this.setState({ published: false });
  }

  async onPublishQuiz(moduleId) {
    const res = await fetch(`${config.apiURL}/quiz/${moduleId}`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(this.state.quizQuestions),
    });

    if (res.status === 200) {
      this.onPublishQuizDetails(moduleId);
    }else{
      throw new Error('could not fetch question types');
    }
  }

  async onPublishQuizDetails(moduleId){
    const res = await fetch(`${config.apiURL}/quiz/${moduleId}/quizDetails`, {
      method: 'POST',
      credentials: 'include',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        resistAllowed: this.state.selectedResitsThresh,
        passingScore: this.state.selectedCorrectAnsThresh,
      }),
    });
    if (res.status !== 200) {
      throw new Error('could not fetch quiz details ');
    }
  }

  onChangeFile(e) {
    e.preventDefault();
    const selectedFiles = this.profileAvatarFileInput.files;
    this.setState({
      featuredImage1: URL.createObjectURL(e.target.files[0]),
    });
    this.readFile(selectedFiles[0]);
  }

  onChangeImageFiles(e) {
    e.preventDefault();
    this.setState({ assetUploadError: '' });
    const selectedFiles = this.moduleImageFileInput.files;
    const file = selectedFiles[0];
    this.readImageFile(file);
  }

  onChangeTextContent(textContent) {
    this.setState({ textContent });
    if (this.props.onChange) {
      this.props.onChange(textContent.toString('html', { blockStyleFn: getTextAlignStyles }));
    }
  }

  async getCategories() {
    const body = await fetchCategories();
    const categories = body.map(category =>
      <option key={category.id} value={category.id}>{category.description}</option>);
    this.setState({ categories });
    this.setState({ categoriesList: body });
  }

  async getSubCategories() {
    const activityOptions = await fetchActivities();
    const targetGroupOptions = await fetchTargetGroups();
    this.setState({ activityOptions, targetGroupOptions });
  }

  async getHubMembers(hubId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${hubId}`, {
        credentials: 'include',
        method: 'GET',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status === 200) {
        const hubInfo = await res.json();
        this.setState({ hubMembers: hubInfo.users.filter(member => member.id !== this.props.user.id) });
      }
    } catch (err) {
      console.log(err);
    }
  }

  async getMembers(moduleId) {
    let retrieve_members = [];
    let retrieve_members2 = [];

    try {
      const res = await fetch(`${config.apiURL}/module/${moduleId}/members`, {
        credentials: 'include',
        method: 'GET',
        headers: {
          'content-type': 'application/json'
        },
      });
      const body = await res.json();

      body.forEach(async (x) => {
        const res2 = await fetch(`${config.apiURL}/user/${x.userId}`, {
          credentials: 'include',
          method: 'GET',
          headers: {
            'content-type': 'application/json'
          },
        });
        const body2 = await res2.json();
        retrieve_members.push(body2);
        retrieve_members2.push(body2);

        if (this.props.user && this.props.user.id != null && x.userId === this.props.user.id) {
          this.setState({ userMember: true });
        }
      });
    } catch (err) {
      console.log(err);
    }

    if (this.state.members.length === 0) {
      this.setState({ members: retrieve_members, membersOld: retrieve_members2 });
    }
  }

  addQuizQuestion(question) {
    this.setState({
      quizQuestions: [...this.state.quizQuestions, question],
    });
  }

  editQuizQuestion(index, newQuestion) {
    if (index < 0 || index >= this.state.quizQuestions.length) {
      console.error("Invalid index. Cannot replace question.");
      return;
    }

    const updatedQuestions = [...this.state.quizQuestions];
    // copy timestamp of old questions into the edited question
    let createdAt = updatedQuestions[index].createdAt
    if (createdAt) { newQuestion.createdAt = createdAt };

    updatedQuestions[index] = newQuestion;

    this.setState({
      quizQuestions: updatedQuestions,
    });
  }

  removeQuizQuestion(index) {
    const { quizQuestions } = this.state;
    quizQuestions.splice(index, 1);
    this.setState({
      quizQuestions: quizQuestions,
    });
  }
  onQuizQuestionsReorder(reorderedQuestions) {
    this.setState({
      quizQuestions: reorderedQuestions,
    });
  }

  addMember(user) {
    this.setState({
      members: [...this.state.members, user],
    });
  }

  removeMember(user) {
    const membersList = this.state.members;
    membersList.splice(membersList.indexOf(user), 1);
    this.setState({
      members: membersList,
    });
  }

  async attachVideoLink() {
    const { videoEmbedLink } = this.state;
    if (videoEmbedLink === '') {
      return;
    }
    const videoURL = videoEmbedLink.toLowerCase();
    const sourceId = /youtube/i.test(videoURL) ? 1 : /vimeo/i.test(videoURL) ? 2 : null;
    if (!sourceId) {
      this.setState({
        videoEmbedLinkError: 'Only Youtube and Vimeo links are supported',
        errorMessage: 'Only Youtube and Vimeo links are supported',
      });
      return;
    }
    this.setState({
      embeddedVideos: [...this.state.embeddedVideos, this.state.videoEmbedLink],
      videoEmbedLink: '',
    });
  }

  readFile(file) {
    const reader = new FileReader();
    const acceptedList = ['image/PNG', 'image/png', 'image/jpeg', 'image/jpg', 'image/JPEG'];
    reader.onloadend = () => {
      if (file.size > 5000000) {
        this.setState({
          featuredImage: null,
          featuredImageHelpText: 'Files must be less than 5mb',
          featuredImageFileType: '',
          featuredImageTitle: '',
          validImage: false,
        });
        return;
      }
      if (!acceptedList.includes(file.type)) {
        this.setState({
          featuredImage: null,
          featuredImageHelpText: 'Images must be jpeg or png',
          featuredImageFileType: '',
          featuredImageTitle: '',
          validImage: false,
        });
        return;
      }
      this.setState({
        featuredImage: reader.result,
        featuredImageFileType: file.type,
        featuredImageHelpText: '',
        featuredImageTitle: file.name,
        validImage: true,
      });
    };
    if (file) {
      reader.readAsArrayBuffer(file);
    }
  }

  readImageFile(file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      let { pdfCount } = this.state;
      let { imageCount } = this.state;
      if (config.acceptedImageTypes.includes(file.type)) {
        imageCount += 1;
      } else if (config.acceptedPdfTypes.includes(file.type)) {
        pdfCount += 1;
      } else {
        this.setState({
          assetUploadError: 'Unsupported file type',
          errorMessage: 'Unsupported file type',
        });
        return;
      }
      const item = {
        data: reader.result,
        fileType: file.type,
        title: file.name,
      };
      const uploadedFiles = [...this.state.uploadedFiles, item];
      this.setState({
        uploadedFiles,
        pdfCount,
        imageCount,
      });
    };
    reader.readAsArrayBuffer(file);
  }

  handleActivityCheckboxClick(e) {
    const index = this.state.activitiesSelected.indexOf(e);
    if (index === -1) {
      const activitiesSelected = [...this.state.activitiesSelected, e];
      this.setState({ activitiesSelected });
    } else {
      const { activitiesSelected } = this.state;
      activitiesSelected.splice(index, 1);
      this.setState({ activitiesSelected });
    }
  }

  handleGroupCheckboxClick(e) {
    const index = this.state.targetGroupsSelected.indexOf(e);
    if (index === -1) {
      const targetGroupsSelected = [...this.state.targetGroupsSelected, e];
      this.setState({ targetGroupsSelected });
    } else {
      const { targetGroupsSelected } = this.state;
      targetGroupsSelected.splice(index, 1);
      this.setState({ targetGroupsSelected });
    }
  }

  openAsset(asset) {
    window.open(`${config.spacesURL}/${asset}`);
  }

  removeAssetPdf(asset) {
    this.setState(prevState => ({ pdfCount: prevState.pdfCount - 1 }));
    const { uploadedFiles } = this.state;
    const i = uploadedFiles.findIndex(file => file.id === asset.id);
    uploadedFiles.splice(i, 1);
    this.setState({ uploadedFiles });
  }

  removeAssetImage(asset) {
    this.setState(prevState => ({ imageCount: prevState.imageCount - 1 }));
    const { uploadedFiles } = this.state;
    const i = uploadedFiles.findIndex(file => file.id === asset.id);
    uploadedFiles.splice(i, 1);
    this.setState({ uploadedFiles });
  }

  removeVideo(video) {
    const { embeddedVideos } = this.state;
    const i = embeddedVideos.findIndex(url => url === video);
    embeddedVideos.splice(i, 1);
    this.setState({ embeddedVideos });
  }

  toggleFilter() {
    this.setState({ showFilter: !this.state.showFilter });
  }

  renderAssets(asset) {
    if (config.acceptedPdfTypes.includes(asset.fileType)) {
      return (
        <Col key={asset.title} sm={2} className="pdf-col" >
          <i className="clickable fa fa-file-pdf-o fa-5x" aria-hidden="true" onClick={() => this.openAsset(asset.id)} />
          <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.removeAssetPdf(asset)} />
          <p className="asset-title">{asset.title}</p>
        </Col>
      );
    }
    if (config.acceptedImageTypes.includes(asset.fileType)) {
      return (
        <Col key={asset.title} sm={2} className="pdf-col" >
          <i className="clickable fa fa-file-image-o fa-5x" aria-hidden="true" onClick={() => this.openAsset(asset.id)} />
          <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.removeAssetImage(asset)} />
          <p className="asset-title">{asset.title}</p>
        </Col>
      );
    }
    return null;
  }

  renderVideoAssets(video, i) {
    return (
      <Col key={i} sm={2} className="pdf-col" >
        <i className="clickable fa fa-play fa-5x" aria-hidden="true" />
        <i className="clickable fa fa-times fa-2x" aria-hidden="true" onClick={() => this.removeVideo(video)} />
        <p className="asset-title">{video}</p>
      </Col>
    );
  }

  addActivity() {
    if (this.state.selectedActivity === '') {
      return;
    }
    this.setState({
      activitiesSelected: [...this.state.activitiesSelected, parseInt(this.state.selectedActivity, 10)],
      selectedActivity: '',
    });
  }

  removeActivityClick(e) {
    const activity = e.target.id;
    this.setState({
      activitiesSelected: this.state.activitiesSelected.filter(i => i !== parseInt(activity, 10)),
    });
  }

  renderChosenActivities() {
    if (this.state.activityOptions.length > 0) {
      return this.state.activitiesSelected.map((id) => {
        const activity = this.state.activityOptions.find(i => i.id === Number(id));
        return (
          <div key={id} className="selection-container">
            <div className="selection-text">{activity.description}</div>
            <div className="selection-icon">
              <i
                id={id}
                className="fa fa-times-circle fa"
                aria-hidden="true"
                onClick={e => this.removeActivityClick(e)}
              />
            </div>
          </div>
        );
      });
    }

    return (
      <span>loading</span>
    )
  }

  addTarget() {
    if (this.state.selectedTarget === '') {
      return;
    }
    this.setState({
      targetGroupsSelected: [...this.state.targetGroupsSelected, parseInt(this.state.selectedTarget, 10)],
      selectedTarget: '',
    });
  }

  removeTargetClick(e) {
    const target = e.target.id;
    this.setState({
      targetGroupsSelected: this.state.targetGroupsSelected.filter(i => i !== parseInt(target, 10)),
    });
  }

  renderChosenTargets() {
    if (this.state.targetGroupOptions.length > 0) {
      return this.state.targetGroupsSelected.map((id) => {
        const target = this.state.targetGroupOptions.find(i => i.id === Number(id));
        return (
          <div key={id} className="selection-container">
            <div className="selection-text">{target.description}</div>
            <div className="selection-icon">
              <i
                id={id}
                className="fa fa-times-circle fa"
                aria-hidden="true"
                onClick={e => this.removeTargetClick(e)}
              />
            </div>
          </div>
        );
      });
    }
    return (
      <span>Loading</span>
    )
  }


  render() {
    const activities = this.state.activityOptions.filter(i =>
      !this.state.activitiesSelected.includes(i.id));
    const targetGroups = this.state.targetGroupOptions.filter(i =>
      !this.state.targetGroupsSelected.includes(i.id));

    const uploadLimitReached = (this.state.imageCount + this.state.pdfCount) >= config.moduleFileLimit;
    const videoLimitReached = this.state.embeddedVideos.length >= config.moduleVideoLimit;
    const visibility = [1, 2, 3,4];
    const approved = this.props.approved

    const name = this.state.category == 3 ? 'Quiz' : 'Module'
    if (!approved) {
      return (
        <div className="not-approved">
          <h4>Unapproved Hub</h4>
          A hub must be approved before adding content.
        </div>
      );
    }

    const Target = ({ active, target, onClick }) => {
      if (active) {
        return (
          <div onClick={onClick} className="targets-selected" style={{ cursor: 'pointer' }} >
            {target}
            <img src={tick} alt="" style={{ marginLeft: "20px" }} />
          </div>
        );
      }
      return (
        <div onClick={onClick} className="targets" style={{ cursor: 'pointer' }} >
          {target}
        </div>
      );
    };

    const Visibility = ({ active, description, target, onClick, img1 }) => {
      if (active) {
        return (
          <div style={{ flex: ' 1' }}>
            <div onClick={onClick} className="visibility-selected" >
              <img src={img1} alt="" style={{ marginRight: "20px" }} />
              <div style={{ textAlign:"center"}}>{target}</div>
              <img src={tick} alt="" style={{ marginLeft: "20px" }} />
            </div>
            <div className="radio-sub-text">{description}</div>
          </div>
        );
      }
      return (
        <div style={{ flex: ' 1' }}>
          <div onClick={onClick} className="visibilty" style={{ cursor: 'pointer' }}>
            <img src={img1} alt="" style={{ marginRight: "20px" }} />
            <div style={{ textAlign:"center"}}>{target}</div>
          </div>
          <div className="radio-sub-text">{description}</div>
        </div>
      );
    };
    return (
      <div className="create-module-tab-container" >
        <div className="padding">
          <div className="form-heading-container">
            {!this.props.edit && <div className='back-button-container'>
              <Button className='back-button' onClick={() => { this.props.onClickBack() }}>Back</Button>
            </div >}
            <span>
              <h1 className="form-heading">
                {this.props.edit ? `Edit ${name}` : `Create a New ${name}`}
              </h1>
            </span>
          </div>
          <div className="form-container">
            <FormGroup className="form" controlId="title" validationState={!this.state.validTitle ? 'error' : null}>
              <ControlLabel>{`${name} Title:`}</ControlLabel>
              <div style={{ flex: '1 1' }}>
                <FormControl
                  autoFocus
                  className="form-input"
                  type="text"
                  value={this.state.title}
                  onChange={e => this.setState({ title: e.target.value })}
                />
                {!this.state.validTitle && <HelpBlock>{`Must Enter ${name} Title`}</HelpBlock>}
              </div>
            </FormGroup>
            <FormGroup className="form" controlId="description" validationState={!this.state.validDescription ? 'error' : null}>
              <ControlLabel>{`${name} Purpose:`}</ControlLabel>
              <div style={{ flex: '1 1' }}>
                <FormControl
                  className="form-input"
                  type="text"
                  value={this.state.description}
                  onChange={e => this.setState({ description: e.target.value })}
                />
                {!this.state.validDescription && <HelpBlock>{`Must Enter ${name} Purpose`}</HelpBlock>}
              </div>
            </FormGroup>
            <RTE
              textContent={this.state.textContent}
              validTextContent={this.state.validTextContent}
              onChangeTextContent={(v) => this.onChangeTextContent(v)}
            />
            <FormGroup className="form" controlId="form" validationState={!this.state.validImage ? 'error' : null}>
              <div style={{ display: "block", flex: "1" }}>
                <ControlLabel className="featured-title">{`${name} Thumbnail`}</ControlLabel>
                <HelpBlock>Recommended Size 180 x 100</HelpBlock>
              </div>
              <div className="form-button-container-featureImage">
                <img className='img1' alt="featured" src={this.state.featuredImage1} />
                <Button className="attach-button-featureImage button" onClick={() => this.profileAvatarFileInput.click()}>
                  {this.state.featuredImage ? 'Upload' : 'Upload'}
                </Button>
                {!this.state.validImage && (<HelpBlock>{this.state.featuredImageHelpText || `${name} Thumbnail is required`}</HelpBlock>)}
              </div>
            </FormGroup>

            {this.state.category != 3 &&
              <FormGroup className="form video-form" controlId="formVideo" validationState={this.state.videoEmbedLinkError ? 'error' : null}>
                <div style={{ display: "block", flex: "0.58 1" }}>
                  <ControlLabel>Upload a Video</ControlLabel>
                  <HelpBlock>Use Youtube or Vimeo links</HelpBlock>
                </div>
                <div style={{ flex: '0.58 1' }}>
                  <FormControl
                    className="form-upload"
                    type="text"
                    value={this.state.videoEmbedLink}
                    onChange={e => this.setState({ videoEmbedLink: e.target.value, videoEmbedLinkError: '' })}
                  />
                  {this.state.videoEmbedLinkError && <HelpBlock>{this.state.videoEmbedLinkError}</HelpBlock>}
                </div>
                <div className="form-button-container">
                  <Button className="attach-button button" disabled={videoLimitReached} onClick={e => this.attachVideoLink(e)}>Upload</Button>
                </div>
              </FormGroup>
            }
            {this.state.category != 3 &&
              <FormGroup className="form pdf-form" controlId="formPDF" validationState={uploadLimitReached || this.state.assetUploadError ? 'error' : null}>
                <ControlLabel>Upload image, PDF, or ZIP Folder</ControlLabel>
                <div style={{ flex: '0.58 1' }}>
                </div>
                <div className="form-button-container">
                  <Button className="attach-button button" disabled={uploadLimitReached} onClick={() => this.moduleImageFileInput.click()}>Browse</Button>
                  {(uploadLimitReached || this.state.assetUploadError) && <HelpBlock>{this.state.assetUploadError ? this.state.assetUploadError : 'Upload limit reached'}</HelpBlock>}
                </div>
              </FormGroup>
            }
            {this.state.category != 3 &&
              <Row className="upload-counter-container">

                <Col xs={1} className="video-container">
                  <p className="count-text">{this.state.embeddedVideos.length}</p>
                  <i className="fa fa-play fa-2x" aria-hidden="true" />
                </Col>
                <Col xs={1} className="pdf-container">
                  <p className="count-text">{this.state.pdfCount}</p>
                  <i className="fa fa-file-pdf-o fa-2x" aria-hidden="true" />
                </Col>
                <Col xs={1} className="pdf-container">
                  <p className="count-text">{this.state.imageCount}</p>
                  <i className="fa fa-file-image-o fa-2x" aria-hidden="true" />
                </Col>
              </Row>
            }
            {(this.state.uploadedFiles.length > 0 || this.state.embeddedVideos.length > 0) &&
              <Row>
                <div className="padding-line" />
                <h4 className="resource-heading">Resources</h4>
                {this.state.uploadedFiles.map(asset => this.renderAssets(asset))}
                {this.state.embeddedVideos.map((video, i) => this.renderVideoAssets(video, i))}
              </Row>
            }
            {this.state.category != 3 &&
              <FormGroup className="form" controlId="formRadio" validationState={!this.state.validAccessId ? 'error' : null}>
                <ControlLabel>Visibility</ControlLabel>
                <div className="visibility-options">
                  {visibility.map((t) => {
                    if ((this.state.category !== 3 && this.state.category !== '3') && t === 1) {
                      return (
                        <Visibility
                          key={t}
                          target={"Public"}
                          active={t === this.state.accessId}
                          description={"Visible to anybody"}
                          onClick={() => {
                            this.setState({ accessId: t });
                          }}
                          img1={publicIcon}
                        />
                      );
                    }
                    else if (t === 2) {
                      return (
                        <Visibility
                          key={t}
                          target={"Private"}
                          active={t === this.state.accessId}
                          description={"Only visible to hub members"}
                          onClick={() => {
                            this.setState({ accessId: t });
                          }}
                          img1={eyeVisibleIcon}
                        />
                      );
                    }
                    else if (t === 3) {
                      return (
                        <Visibility
                          key={t}
                          target={"Hidden"}
                          active={t === this.state.accessId}
                          description={"Only visible to hub members you choose"}
                          onClick={() => {
                            this.setState({ accessId: t });
                          }}
                          img1={HiddenIcon}
                        />
                      );
                    }
                    else if (t === 4) {
                      return (
                        <Visibility
                          key={t}
                          target={"Course Only"}
                          active={t === this.state.accessId}
                          description={"Only visible to select for eLearning Courses"}
                          onClick={() => {
                            this.setState({ accessId: t });
                          }}
                          img1={CourseIcon}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
                {!this.state.validAccessId && <HelpBlock>Must Select Access Level</HelpBlock>}
              </FormGroup>
            }
            {this.state.category != 3 &&
              <div>
                {this.state.accessId === 3 && <p style={{ position: "relative", top: "50%", textAlign: "right" }}>Use the search below to add users!</p>}
              </div>
            }
            {this.state.category != 3 &&
              (this.state.accessId === 3 && <ModuleMembers hubMembers={this.state.hubMembers} members={this.state.members} user={this.props.user} module={this.props.module} addMemberBound={this.addMember} removeMemberBound={this.removeMember} />)
            }
            <FormGroup className="form" controlId="formCategory">
              <ControlLabel>Comments</ControlLabel>
              <div style={{ display: "block", flex: "1" }}>
                <Checkbox className='checkbox' name="commentGroup" onChange={() => this.setState({ commentsEnabled: 1 })} checked={this.state.commentsEnabled === 1}>
                  Enabled
                </Checkbox>
                <Checkbox className='checkbox' name="commentGroup" onChange={() => this.setState({ commentsEnabled: 0 })} checked={!this.state.commentsEnabled}>
                  Disabled
                </Checkbox>
              </div>
            </FormGroup>
            {this.state.category != 3 &&
              <FormGroup className="form" controlId="formCategory">
                <ControlLabel>Activities</ControlLabel>
                <div style={{ display: "block", flex: "1" }}>
                  <FormControl
                    className="activity-select"
                    componentClass="select"
                    onChange={e => {
                      this.setState({ selectedActivity: e.target.value }, () => {
                        this.addActivity();
                      },);
                    }}
                    size="lg"
                  >
                    <option value="select">Select Activity</option>
                    {activities.map(activity => (
                      <option key={activity.id} value={activity.id}>{activity.description}</option>
                    ))}
                  </FormControl>
                  <div className="chosen-goals-container">
                    {this.renderChosenActivities()}
                  </div>
                </div>
              </FormGroup>
            }
            {this.state.category != 3 &&
              <FormGroup className="form" controlId="formCategory">
                <ControlLabel>Target Groups</ControlLabel>
                <div style={{ display: "block", flex: "1" }}>
                  <FormControl
                    className="targetGroup-select"
                    componentClass="select"
                    onChange={e => {
                      this.setState({ selectedTarget: e.target.value }, () => this.addTarget())
                    }}
                  >
                    <option value="select">Select Group</option>
                    {targetGroups.map(targetGroup => (
                      <option key={targetGroup.id} value={targetGroup.id}>{targetGroup.description}</option>
                    ))}
                  </FormControl>
                  <div className="chosen-goals-container">
                    {this.renderChosenTargets()}
                  </div>
                </div>
              </FormGroup>
            }
            {(this.state.category === 3 || this.state.category === '3') && <QuizBuilder addQuizQuestion={this.addQuizQuestion} editQuizQuestion={this.editQuizQuestion} removeQuizQuestion={index => this.removeQuizQuestion(index)} onQuizQuestionsReorder={this.onQuizQuestionsReorder} questions={this.state.quizQuestions} />}
          </div>
        {/* {(this.state.category === 3 || this.state.category === '3') &&
        //   <div className='quiz-details'>
        //   <div className='quiz-details-header'> Quiz Details</div>
        //   <FormGroup className="form" controlId="formCategory">
        //     <ControlLabel>Select number of question user must answer correctly to progress:</ControlLabel>
        //     <div  style={{ display: "block", flex: "0.1" }}>
        //       <FormControl
        //         className= "quiz-select"
        //         componentClass="select"
        //         value={this.state.selectedCorrectAnsThresh}
        //         onChange={e => {
        //           this.setState({ selectedCorrectAnsThresh: e.target.value });
        //         }}
        //         size="lg"
        //       >
        //         {Array.from({ length: this.state.quizQuestions.length + 1 }, (_, index) => (
        //           <option key={index} value={index}>{index}</option>
        //         ))}
        //       </FormControl>
        //     </div>
        //   </FormGroup>
        //   <FormGroup className="form" controlId="formCategory">
        //     <ControlLabel>Select number of resists allowed:</ControlLabel>
        //     <div  style={{ display: "block", flex: "0.1" }}>
        //       <FormControl
        //         className= "quiz-select"
        //         componentClass="select"
        //         value={this.state.selectedResitsThresh}
        //         onChange={e => {
        //           this.setState({ selectedResitsThresh: e.target.value });
        //         }}
        //         size="lg"
        //       >
        //          <option value="999"> Infinite</option>
        //         {Array.from({ length: 5 }, (_, index) => (
        //           <option key={5 - index} value={5 - index}>{5 - index}</option>
        //         ))}
        //       </FormControl>
        //     </div>
        //   </FormGroup>
        // </div>
  } */}

          {this.state.showModal && <div className='blur' />}
          {this.state.showModal && (
            <div className="gif-container" backdropOpacity={0.3}>
              <img className='gif' src={loadingAnimation} alt="loading" />
            </div>)}

          <div className="publish-feedback">
            {this.state.published && (this.props.edit ? 'Updating module...' : 'Creating module...')}
          </div>
          {this.state.errorMessage &&
            <span style={{ marginRight: '1em', color: '#a94442' }}>
              {this.state.errorMessage}
            </span>
          }
          <div className="publish-button-container">
            <Button className="publish-button button" onClick={() => this.onPublishClick()}>
              {this.props.edit ? 'Update' : 'Publish'}
            </Button>
          </div>
          <div style={{ display: 'none' }}>
            <input
              ref={(f) => { this.profileAvatarFileInput = f; }}
              className="form-input logo"
              type="file"
              onChange={e => this.onChangeFile(e)}
              accept="image/*"
            />

            <input
              ref={(f) => { this.moduleImageFileInput = f; }}
              className="form-input logo"
              type="file"
              onChange={e => this.onChangeImageFiles(e)}
              accept="image/jpeg,image/png,application/pdf,.zip"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CreateModule;
