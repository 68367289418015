import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './RemoveCourse.css';

class RemoveUser extends Component {
  render() {
    const { removeCourse, closeModal } = this.props;
    return (
      <div className="remove-course-modal-container">
        <div>
          <div className="text-container">
            <p>
              Are you sure you wish to remove this course?
            </p>
          </div>
          <div className="button-container">
            <Button onClick={() => removeCourse()}>Yes</Button>
            <Button onClick={() => closeModal()}>No</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveUser;