import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { hubAccessLevels } from '../../constant/config';
import { dateCompare } from '../../lib/utilities';
import CarouselSection from './CarouselSection';
import './HomeTab.css';


class HomeTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showingInfo : false,
    };
  }

  getNoModuleContent() {
    if (this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      return (
        <div className="no-modules">
          <div className="no-module-text">
          This hub does not have any modules yet, to get started create your first module in the Create tab above.
          </div>
          {/* <Button
            className="create-module-button"
            onClick={() => this.props.changeTab(4)}
          >
            Create Module
          </Button> */}
        </div>
      );
    }
  }

  onMakeFeaturedClick() {
    this.props.changeTab(7)
  }

  getNoFeaturedModule() {
    if (this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      return (
        <div className="no-modules">
          <div className="no-module-text">
            <h3 className="section-title">
              <div>
                Featured Modules
              </div>
              <span>
                <Button
                  className="select-featured-button"
                  onClick={() => this.onMakeFeaturedClick()}
                >
                  Select Featured Modules  →
                </Button>
              </span>
            </h3>

            <div className="sub-title">There are no featured modules selected</div>
            <div className='description'>
              Featuring will let your users know about the content your hub has at a glance. You can choose to set eight featured modules from Hubs > Settings > Featured Modules, or click on the ‘Select Featured Modules’ button above.
            </div>
          </div>

        </div>
      );
    }
    return (
      <div className="no-modules">
        {this.props.user ? 'It looks like the hub admins have not added any featured modules yet. Please check back later.'
          : 'It looks like the hub admins have not added any public featured modules yet. Please check back later.'}
      </div>
    );
  }

  getNoFeaturedFolder() {
    if (this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      return (
        <div className="no-modules">
          <div className="no-module-text">
            <h3 className="section-title">
              <div>
                Featured Folders
              </div>
              <span>
                <Button
                  className="select-featured-button"
                  onClick={() => this.onMakeFeaturedClick()}
                >
                  Select Featured Folders  →
                </Button>
              </span>
            </h3>

            <div className="sub-title">There are no featured folders selected</div>
            <div className='description'>
              Featuring will let your users know about the content your hub has at a glance. You can choose to set  featured folders from Hubs > Settings > Featured Folders, or click on the ‘Select Featured Folders’ button above.
            </div>
          </div>

        </div>
      );
    }
    return (
      <div className="no-modules">
        {this.props.user ? 'It looks like the hub admins have not added any featured folders yet. Please check back later.'
          : 'It looks like the hub admins have not added any public featured folders yet. Please check back later.'}
      </div>
    );
  }

  render() {
    let infoHeaderModule;
    let infoHeaderFolder;
    let infoTextModule;
    let infoTextFolder;

    if (this.props.hub.userAccessLevel >= hubAccessLevels.HUB_ADMIN) {
      infoHeaderModule = "What are Featured Modules?"
      infoTextModule = "Featuring will let your users know about the content your hub has at a glance. You can choose to set eight featured modules from Hubs > Settings > Featured Modules."
      infoHeaderFolder = "What are Featured Folders?"
      infoTextFolder = "Featuring will let your users know about the content your hub has at a glance. You can choose to set eight featured folders from Hubs > Settings > Featured Folders."
    }
    const modulesFeatured = this.props.featuredModules;
    const foldersFeatured = this.props.featuredFolders;
    return (
      <div className="home-tab-container">
        {!this.props.modules.length > 0 && this.getNoModuleContent()}

        <div className='featured-modules'>
          {!modulesFeatured.length > 0 && this.getNoFeaturedModule()}
          {modulesFeatured.length > 0 &&
            <CarouselSection noExpand context="modules" title="Featured Modules" tiles={modulesFeatured} infinite={true} infoHeader={infoHeaderModule} infoText={infoTextModule} />}
            </div>
        <div className='featured-folders'>
          {!foldersFeatured.length > 0 && this.getNoFeaturedFolder()}
          {foldersFeatured.length > 0 && <CarouselSection noExpand context="plans" title="Featured Folders" tiles={foldersFeatured} hub={this.props.hub} infinite={true} infoHeader={infoHeaderFolder} infoText={infoTextFolder}/>}
        </div>
      </div>
    );
  }
}

export default HomeTab;
