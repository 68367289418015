import React, { Component } from 'react';
import {
  Row, Col, Button
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Tile from './Tile';
import HubTile from './HubTile';
import UserTile from './UserTile';
import PlanTile from './PlanTile';
import './Section.css';


class Section extends Component {

  constructor(props){
    super(props)
    this.state={
      showCol : true,
      viewMore: false,
    }
  }

  renderTiles(tile, index) {
    return (
      <Tile key={index} hub={tile.hub} hubPathName={tile.hub.pathName} hubId={tile.hubId} id={tile.id} moduleTitle={tile.title} category={tile.category.description} hubName={tile.hub.name} hubImage={tile.hub.logoId} image={tile.logoId} accessId={tile.accessId} createdAt={tile.createdAt} small={this.props.small} moduleTab={true}/>
    );
  }
  renderPlanTiles(tile, index, hub) {
    return (
      <PlanTile key={index} hubPathName={hub.pathName} hubId={tile.hubId} id={tile.id} moduleTitle={tile.title} accessId={tile.accessId} category="Folder" hubName={hub.name} image={tile.logoId} hubImage={hub.logoId}/>
    );
  }

  renderHubTiles(tile, index) {
    return (
      <HubTile addHub={this.props.addHub ? this.props.addHub : null} hubPathName={tile.pathName} id={tile.id} selectable={this.props.selectable} key={index} hubName={tile.name} image={tile.logoId} small={true} />
    );
  }

  renderUserTiles(tile, index) {
    const fullName = `${tile.firstName} ${tile.surname}`;
    return (
      <UserTile id={tile.id} key={index} hubName={fullName} image={tile.avatarId} userClick={this.props.userClick} user={tile} selectable={this.props.selectable} onCheckboxClick={this.props.onCheckboxClick} />
    );
  }

  renderTitle() {
    if (!this.props.selectable) {
      if (this.props.sectionLink) {
        return (
          <div>
            <h3 className="section-title">{this.props.title}</h3>
            <Link to={this.props.sectionLink} className="section-link top-link" onClick={this.props.onClick}>{this.props.sectionLinkText || 'More...'}</Link>
          </div>
        );
      }
      return (<div><h3 className="section-title">{this.props.title}</h3>
      {this.props.moduleTab  && <button className='arrow-button' onClick={() => this.handleToggleCollapse()}>
                      {this.state.showCol ? '\u25BC' : '\u25B6'}
                    </button>}
      </div>);
    }
    return null;
  }

  handleToggleCollapse(){
    this.setState({
showCol: !this.state.showCol
    })
  }

  OnViewMore(){
    this.setState({
      viewMore: !this.state.viewMore
    })
  }


  render() {
    return (
      <Row className="section-container">
        {this.props.title && this.renderTitle()}
        { this.state.showCol && <Col className="content-container">
          <div className='tiles-container'>
            {this.props.context === 'modules' && this.props.moduleTab && this.props.tiles
  .slice(0, !this.state.viewMore ? 4 : this.props.tiles.length) 
  .map((tile, index) => this.renderTiles(tile, index))}
            {this.props.context === 'modules' && !this.props.moduleTab && this.props.tiles.map((tile, index) => this.renderTiles(tile, index))}
            {this.props.context === 'hubs' && this.props.tiles.map((tile, index) => this.renderHubTiles(tile, index))}
            {this.props.context === 'users' && this.props.tiles.map((tile, index) => this.renderUserTiles(tile, index))}
            {this.props.context === 'plans' && this.props.tiles.map((tile, index) => this.renderPlanTiles(tile, index, this.props.hub))}
            {this.props.tiles.length < 1 &&
              <div className="empty-text-container">{this.props.emptyText}</div>
            }
            {this.props.sectionLink &&
              <div className="section-link-container">
                <Link to={this.props.sectionLink}>{this.props.sectionLinkText || 'More...'}</Link>
              </div>
            }
              
          </div>
          { this.props.moduleTab && <div style={{display:'flex',justifyContent:'center'}}>
            <Button className='view-button' onClick={()=>{this.OnViewMore()}}>View {this.state.viewMore? 'Less' : 'More'}
              <div className='circle'>{this.state.viewMore? '-' : '+'}</div>
            </Button>
          </div>}
        </Col >
  }
      </Row >

    );
  }
}

export default Section;
