import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './RemoveGroup.css';

class ChangeElearningStatus extends Component {
  render() {
    const { changeStatus, closeModal,hubName, status } = this.props;
    return (
      <div className="remove-group-modal-container">
        <div>
          <div className="text-container">
            <p>
              {`Are you sure to ${status?'disable':'enable'} elearning for the ${hubName} hub?`}
            </p>
          </div>
          <div className="button-container">
            <Button onClick={() => changeStatus()}>Yes</Button>
            <Button onClick={() => closeModal()}>No</Button>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangeElearningStatus;