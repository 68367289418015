import React, { Component } from 'react';
import { Button, Row, Col, FormGroup, ControlLabel, FormControl, Modal } from 'react-bootstrap';
import './CreateGroup.css';
import DatePicker from 'react-date-picker';
import config, { hubAccessLevels } from '../../constant/config';
import RemoveUser from './RemoveUser';
import RemoveCourse from './RemoveCourse';
import AssignCourse from './AssignCourse';
import AddUsersToGroup from './AddUsersToGroup';
import loadingAnimation from "../../resources/img/loading_animation.gif";


export class CreateGroup extends Component {

    constructor(props) {
        super(props);
        this.state = {
            groupName: this.props.groupName,
            uniqueUsers: this.props.users,
            courses: this.props.courses || [],
            showCourses: true,
            showUsers: true,
            showModal:false,
            modalContent:null,
            hubUsers:this.props.hubUsers,
            published:false,

        }
    }



    ChangeStatusOfAssignedCourses = () => {
        this.setState(
            {
                showCourses: this.state.showCourses ? false : true,
            }
        );
    }

    ChangeStatusOfGroupUsers = () => {
        this.setState(
            {
                showUsers: this.state.showUsers ? false : true,
            }
        );
    }

    onChangeDate = (courseId, date) => {
        const updatedCourses = this.state.courses.map(course =>
            course.id === courseId ? { ...course, deadline: date } : course
        );
        this.setState({
            courses: updatedCourses
        });

    }

    deleteCourse = (courseId) => {
        // Create a new array excluding the course with the given courseId
        const updatedCourses = this.state.courses.filter(course => course.id !== courseId);

        // Update the state with the new courses array
        this.setState({
            courses: updatedCourses,
            showModal : false,
        });
    }

    deleteUser = (userId) => {

        const updatedUsers = this.state.uniqueUsers.filter(eachUser => eachUser.id !== userId)

        this.setState(
            {
                uniqueUsers: updatedUsers,
                showModal:false
            }
        )
    }

    addUser =(user) =>{
        this.setState((prevState) => ({
            uniqueUsers: [...prevState.uniqueUsers, user],
            showModal:false,
          }));
          
    }

    closeModal = () =>{
        this.setState({
            showModal : false,
        })
        if(this.state.successMessage){
            this.props.OnClickBack()
        }
    }

    renderRemoveConfirmUser(user) {
        this.setState({
          showModal: true,
          modalContent: <RemoveUser
            closeModal={() => this.closeModal()}
            removeUser={() => this.deleteUser(user.id)}
          />,
        });
      }
    
      renderRemoveConfirmCourse(courseId) {
        this.setState({
          showModal: true,
          modalContent: <RemoveCourse
            closeModal={() => this.closeModal()}
            removeCourse={() => this.deleteCourse(courseId)}
          />,
        });
      }

      showAssignCourse(){
        this.setState({
            showModal: true,
            modalContent: <AssignCourse
              closeModal={() => this.closeModal()}
              saveCourse={(course,date)=>{this.addCourse(course,date)}}/* change the save function */
              coursesInHub ={this.props.coursesInHub}
            />,
          });
      }

      addCourse(course,date){
        this.setState({
            courses: [...this.state.courses,{id: course.id, name:course.title, deadline: date}],
            showModal: false,
        })
    }

      showAddUsersToGroup(){
        this.setState({
            showModal: true,
            modalContent: <AddUsersToGroup 
            addUser ={(user) =>this.addUser(user)}
            hubUsers ={ this.state.hubUsers}
            />,
          });
      }

      async saveGroupDetails(){
        this.setState({
            published: true})
        try{
const hubId = this.props.hubId
    const res = await fetch(`${config.apiURL}/hub/${hubId}/group/`, {
      credentials: 'include',
      method: this.props.create ? 'POST' : 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        id: this.props.groupId || null,
        users:this.state.uniqueUsers,
        name: this.state.groupName,
        user:this.props.user,
        courses: this.state.courses,
      }),
    });
    if (res.status === 200) {
        this.setState({ published: false });
        this.setState({
            showModal: true,
            modalContent: (
                <div style={{ padding: '20px', textAlign: 'center', fontSize: '18px' }}>
                  You have successfully created the group. <br />
                  Please refresh the page to view the changes.
                </div>
              ),
            successMessage: true,
          });
      // Add Redux to show the added groups

    } else {
      // TODO: handle error on publishing module
    }
        }
        catch(error){

        }
      }

    render() {
        return (
            <div className='main-container-create-group'>
                <div className='button-header-container'>
                    <div className='button-container'><Button className='header-button' onClick={()=>{this.showAddUsersToGroup()}}>Add User</Button></div>
                    <div className='button-container'><Button className='header-button' onClick={()=>{this.showAssignCourse()}}>Assign Course</Button></div>
                    <div className='button-container'><Button className='header-button' onClick={() => { this.props.OnClickBack() }}>Back</Button></div>

                </div>
                <div className='heading-name'>{this.props.create ? 'Create' : 'Edit'}  Group</div>
                <div className='group-name-container'>
                    <FormGroup className='form-group-name'>
                        <ControlLabel className='control-label-group-name'> Group Name:</ControlLabel>
                        <FormControl
                            autoFocus
                            className="form-input-group-name"
                            type="text"
                            value={this.state.groupName}
                            onChange={e => this.setState({ groupName: e.target.value })}
                        />
                    </FormGroup>
                </div>
                <div className='assigned-courses-main-container'>
                    <div className='sub-label'>Assigned Courses
                        <button className='arrow-button-group-tab' onClick={() => this.ChangeStatusOfAssignedCourses()}>
                            {this.state.showCourses ? '\u25BC' : '\u25B6'}
                        </button>
                    </div>
                    {this.state.showCourses &&
                        <div className='users-table-container'>
                            <table className="users-table">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Deadline</th>  {/*check with aqib again! */}
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {/*Changed need to be done based on users */}
                                    {this.state.courses.map(course => (
                                        <tr key={course.id}>
                                            <td><div className="course-name" onClick={() => { this.onUserClick() }} style={{ fontWeight: 'bold', color: '#646464', fontStyle: "Roboto" }}>{`${course.name}`}</div></td>
                                            <td><div style={{ display: 'flex', justifyContent: 'center' }}><ControlLabel style={{ paddingTop: '10px', fontWeight: 'bold', color: '#646464', fontStyle: "Roboto" }}>Edit Deadline: </ControlLabel>
                                                <FormGroup className="form form-group" style={{ paddingLeft: '10px' }}>
                                                    <DatePicker
                                                    className='datepicker'
                                                        value={course.deadline ? new Date(course.deadline) : null}
                                                        onChange={(date) => this.onChangeDate(course.id, date)}
                                                        clearIcon={null}
                                                        calendarIcon={<i className="fa fa-calendar fa-2x" style={{color :'#14B4C8'}}
/>}
                                                    />
                                                </FormGroup>
                                            </div>
                                            </td>
                                            <td>
                                                <div className="warning-button-container">
                                                    <Button className="warning-button" onClick={() => this.renderRemoveConfirmCourse(course.id)}>Delete Course</Button>
                                                </div>
                                            </td>

                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className='user-main-container'>
                    <div className='sub-label'>Group Users
                        <button className='arrow-button-group-tab' onClick={() => this.ChangeStatusOfGroupUsers()}>
                            {this.state.showUsers ? '\u25BC' : '\u25B6'}
                        </button>
                    </div>
                    {this.state.showUsers &&
                        <div className='users-table-container'>
                            <table className="users-table">
                                <thead>
                                    <tr>
                                        <th>Avatar</th>
                                        <th>Name</th>
                                        <th />
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {/*Changed need to be done based on users */}
                                    {this.state.uniqueUsers.map(user => (
                                        <tr key={user.id}>
                                            <td><div className="avatar-image" style={{ backgroundImage: `url(${config.spacesURL}/${user.avatarId})` }} /></td>
                                            <td><div className="user-name" onClick={() => { this.onUserClick(user) }} style={{ fontWeight: 'bold', color: '#646464', fontStyle: "Roboto" }}>{`${user.firstName} ${user.surname}`}</div></td>
                                            <td>
                                                <div className="warning-button-container">
                                                    <Button className="warning-button" onClick={() => this.renderRemoveConfirmUser(user)}>Remove User</Button>
                                                </div>
                                            </td>

                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
                <div className='save-button-container'>
                    <Button className='save-button' onClick={()=>{this.saveGroupDetails()}}>Save</Button>
                </div>
                <div className="publish-feedback">
            {this.state.published && (this.props.create ?  'Creating Group...': 'Updating Group...' )}
          </div>
                <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {this.state.modalContent}
        </Modal>
        {this.state.published && <div className='blur' />}
          {this.state.published && (
            <div className="gif-container" backdropOpacity={0.3}>
              <img className='gif' src={loadingAnimation} alt="loading" />
            </div>)}
            </div>
        )
    }

}