import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Row, Col, Modal } from 'react-bootstrap';
import './ViewGroup.css'
import { CreateGroup } from './CreateGroup';
import RemoveGroup from './RemoveGroup';
import config from '../../constant/config';

export class ViewGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hubUsers:this.props.users,
      showGroups: true,
      showCreateGroup: false,
      listOfGroups: [],
      selectedGroupName : '',
      courses : '',
      users : '',
      createStatus: true,
      showModal:false,
      modalContent: null,
      editGroup: false,
    }
  }

     async componentDidMount(){
      const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/getCourses`, {
          method: 'GET',
          credentials: 'include',
          headers: { 'content-type': 'application/json' },
        });
        if (res.status == 200) {
          const coursesInHub = await res.json(); 
          this.setState({
            coursesInHub: coursesInHub,
          });
        }

         const resGroups = await fetch(`${config.apiURL}/hub/${this.props.hubId}/getAllGroupsDetail`, {
                method: 'GET',
                credentials: 'include',
                headers: { 'content-type': 'application/json' },
              });
              if (res.status == 200) {
                 const groups = await resGroups.json();  
                 this.setState({
                  listOfGroups:  groups,
                });
              }
    }

  onClickCreateGroup() {
    this.setState({
      showGroups: false,
      showCreateGroup: true,
      createStatus: true,
      courses:[],
      users:[],
      selectedGroupName:'Group Name',
    }

    );

  }

  onClickGroup = (groupId) => {
    const group = this.state.listOfGroups.find(group => group.id === groupId)
    this.setState({
      showGroups: false,
      showCreateGroup: true,
      selectedGroupName: group.title,
      selectedGroupId: group.id,
      courses: group.courses,
      users: group.users,
      createStatus: false,
    }

    );

  }

  onClickQuizResults(){}

  OnClickBack = ()=> {
    this.setState({
      showGroups: true,
      showCreateGroup: false,
    }

    );
  }
  async deleteGroup(groupId) {
    try {
      const res = await fetch(`${config.apiURL}/hub/${this.props.hubId}/group/${groupId}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { 'content-type': 'application/json' },
      });
      if (res.status !== 200) {
        throw new Error('unable to delete group');
      }
      else {
        const updatedGroups = this.state.listOfGroups.filter(group => group.id !== groupId)
        this.setState(
          {
            listOfGroups: updatedGroups,
            showModal: false
          }
        )
      }
    } catch (err) {
      console.error(err.message);
    }


  }

closeModal = () =>{
    this.setState({
        showModal : false,
    })
}

renderRemoveConfirmGroup(groupId) {
    this.setState({
      showModal: true,
      modalContent: <RemoveGroup
        closeModal={() => this.closeModal()}
        removeGroup={() => this.deleteGroup(groupId)}
      />,
    });
  }

  render() {
    return (

      <div className="main-group-container">
        {
          this.state.showGroups && 
        <div>
        <div className="header-container">
          <div className="main-heading">Groups</div>
          <div className='create-button-container'>
            <Button className='create-group-button' onClick={() => { this.onClickCreateGroup() }}>Create Group</Button>
          </div>
        </div>
        <div className='group-table'>
          <Row className='group-row'>
            <Col className='group-col' style={{ fontWeight: 'bold', paddingLeft: '85px', fontSize:'20px' }}>Name</Col>
            <Col className='group-col'></Col>
          </Row>
              {this.state.listOfGroups.map(group => (
                <>
                  <Row className='group-row' key={group.id}>
                    <Col className='group-col'>
                      <div className='group-name-container' style={{ paddingLeft: '70px' }}>
                        <Button className='group-name' style={{ paddingLeft: '20px' }} onClick={() => { this.onClickGroup(group.id) }}>{group.title}</Button>
                      </div>
                      <div className='quiz-button-container'>
                        {/* <Button className='quiz-results-button' onClick={this.onClickQuizResults()}>Quiz Results</Button> */}
                        <Button className="warning-button" onClick={() => this.renderRemoveConfirmGroup(group.id)}>Delete Group</Button>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </div>
          </div>
        }
        {this.state.showCreateGroup && <CreateGroup OnClickBack={this.OnClickBack} create={this.state.createStatus} groupName={this.state.selectedGroupName} groupId = {this.state.selectedGroupId} courses={this.state.courses} users={this.state.users} hubUsers={this.state.hubUsers} hubId={this.props.hubId} user={this.props.user}
        coursesInHub={this.state.coursesInHub}></CreateGroup>}
        <Modal show={this.state.showModal} onHide={this.closeModal}>
          <Modal.Header closeButton />
          {this.state.modalContent}
        </Modal>
      </div>
    );
  }
}


export default withRouter(ViewGroup);